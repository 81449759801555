import { ButtonLink, Row, Typography } from '@/components'
import { ComponentColors } from '@/enums'
import { ArrowRight } from '@/icons'
import { formatMoney } from '@/utils'
import { ResponsivePie } from '@nivo/pie'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ChartData,
  KPIsData,
  chartColors,
  getInternalChartText,
  prepareChartData,
} from './helpers'
import {
  CustomTooltipComponent,
  KPIChartContainer,
  KPILegendColor,
  KPILegendContainer,
  KPILegendItem,
  KPIOverviewContainer,
  MiddleTextWrapper,
} from './styles'

interface KPIOverviewProps {
  data: KPIsData
  grossPrice?: number
  onDetailsClick?: () => void
}

interface CustomTooltipProps {
  datum: {
    color: string
    data: {
      name: string
    }
    value: number
  }
}

interface InternalChartTextProps {
  grossPrice: number
}

interface ChartLegendProps {
  data: {
    id: string
    name: string
    color: string
    value: number
  }[]
}

interface KPIChartProps {
  data: ChartData[]
  grossPrice: number
}

export const KPIOverview = ({
  data,
  grossPrice = 0,
  onDetailsClick,
}: KPIOverviewProps) => {
  const chartData = useMemo(() => prepareChartData(data), [data])
  const { t } = useTranslation()

  return (
    <KPIOverviewContainer hasDetailsLink={!!onDetailsClick}>
      <KPIChart data={chartData} grossPrice={grossPrice} />
      <ChartLegend data={chartData} />
      <ButtonLink
        color={ComponentColors.primary}
        icon={ArrowRight}
        onClick={onDetailsClick}
        reverted
        size="medium"
      >
        {t('kpiOverview.details')}
      </ButtonLink>
    </KPIOverviewContainer>
  )
}

const CustomTooltip = ({ datum }: CustomTooltipProps) => {
  const datumName = [datum.data.name, ':'].join('')

  return (
    <CustomTooltipComponent>
      <KPILegendColor color={datum.color} />
      <Typography bold variant="p2Body">
        {datumName}
      </Typography>
      <Typography variant="p2Body">{formatMoney(datum.value)}</Typography>
    </CustomTooltipComponent>
  )
}

const InternalChartText = ({ grossPrice }: InternalChartTextProps) => {
  const { t } = useTranslation()
  // used to style the decimal to a smaller size

  const { integer, decimal } = getInternalChartText(grossPrice)
  const formattedDecimal = `,${decimal}€`

  return (
    <MiddleTextWrapper>
      <span>{t('kpiOverview.insuranceExpenses')}</span>
      <Typography bold variant="h4">
        {integer}
        <Typography bold variant="badgeText">
          {formattedDecimal}
        </Typography>
      </Typography>
      <span>{t('kpiOverview.perYear')}</span>
    </MiddleTextWrapper>
  )
}

const ChartLegend = ({ data }: ChartLegendProps) => (
  <KPILegendContainer>
    {data.map((item) => (
      <KPILegendItem key={item.id}>
        <Row alignItems="center">
          <KPILegendColor color={item.color} />
          <Typography variant="badgeText">{item.name}</Typography>
        </Row>
        <Typography bold variant="badgeText">
          {formatMoney(item.value)}
        </Typography>
      </KPILegendItem>
    ))}
  </KPILegendContainer>
)

const KPIChart = ({ data, grossPrice }: KPIChartProps) => (
  <KPIChartContainer>
    <ResponsivePie
      activeOuterRadiusOffset={3}
      borderColor={{
        from: 'color',
      }}
      borderWidth={1}
      colors={chartColors}
      cornerRadius={3}
      data={data}
      enableArcLabels={false}
      enableArcLinkLabels={false}
      innerRadius={0.9}
      margin={{ right: 4, left: 4, top: 4, bottom: 4 }}
      padAngle={3}
      tooltip={({ datum }) => <CustomTooltip datum={datum} />}
      valueFormat={(value) => formatMoney(value)}
    />
    <InternalChartText grossPrice={grossPrice} />
  </KPIChartContainer>
)
