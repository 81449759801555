import {
  BackComponent,
  CardSelect,
  ContactExpert,
  Option,
  PageMainContent,
  PageWidgetContainer,
  Typography,
} from '@/components'
import { ThemeColor } from '@/enums'
import { i18nHelpers } from '@/i18n/init'
import { useSnackbar } from '@/providers'
import { useUpdateUserLanguage } from '@/services'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useOutletContext } from 'react-router-dom'
import { PageHeader } from '../styles'
import { UserProfile } from '@/types/user-profile'
import { equals } from 'ramda'

interface OutletContext {
  user: UserProfile
}

export const EditLanguagePage = () => {
  const { i18n, t } = useTranslation()
  const locale = i18n.language
  const { languages } = i18nHelpers
  const { user: data } = useOutletContext<OutletContext>()
  const snackbar = useSnackbar()
  const updateLanguageMutation = useUpdateUserLanguage(data.auth0)
  const queryClient = useQueryClient()

  const onUpdateUser = (language: string) => {
    if (language === locale) {
      return
    }

    updateLanguageMutation
      .mutateAsync({
        language,
      })
      .then(() => {
        snackbar.success(t('snackbars.success.updateUserMsg'))
        i18n.changeLanguage(language)
        queryClient.invalidateQueries('location-types')
      })
      .catch(() => {
        snackbar.error(t('snackbars.error.generalMsg'))
      })
  }

  const selected = languages.find(equals(locale))

  return (
    <>
      <PageMainContent>
        <BackComponent />
        <PageHeader>
          <Typography variant="h4">{t('profile.menu.language')}</Typography>
          <Typography color={ThemeColor.b50} variant="p1Body">
            {t('choose_language')}
          </Typography>
        </PageHeader>
        <CardSelect onSelect={onUpdateUser} value={selected}>
          {languages.map((el: string) => (
            <Option key={el} value={el}>
              <Typography color={ThemeColor.b100} variant="captionB">
                {t(`profile.data.language.${el}`)}
              </Typography>
            </Option>
          ))}
        </CardSelect>
      </PageMainContent>
      <PageWidgetContainer>
        <ContactExpert text={t('advisor_text_base')} />
      </PageWidgetContainer>
    </>
  )
}
