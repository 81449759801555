import {
  BackComponent,
  ButtonLink,
  Column,
  CompanyCard,
  CompanyCardHeader,
  CompanyCardItem,
  ContactExpert,
  Loader,
  PageMainContent,
  PageWidgetContainer,
  Row,
  ShowWhen,
  Typography,
} from '@/components'
import { ComponentColors, ThemeColor } from '@/enums'
import { paths } from '@/routes/paths'
import { useCompanyPayments } from '@/services'
import { UserProfile } from '@/types/user-profile'
import { mapWithKey, returns, useGoToPath } from '@/utils'
import { useTranslation } from 'react-i18next'
import { useOutletContext } from 'react-router-dom'
import { ItemContainer, PageTitle } from '../styles'
import { PaymentInfo } from '@/api'

interface PaymentItemProps {
  paymentId: string
  name?: string
  iban?: string
}

interface ProfilePaymentsListProps {
  companyId: string
  name: string
}

interface OutletContext {
  user: UserProfile
}

export const PaymentItem = ({ paymentId, name, iban }: PaymentItemProps) => {
  const { t } = useTranslation()
  const editItem = useGoToPath(paths.editPaymentWithKey(paymentId))

  return (
    <CompanyCardItem>
      <Column gap="24px">
        {name && (
          <Row alignItems="center" justifyContent="space-between">
            <Column gap="4px">
              <Typography variant="p2Body">{name}</Typography>
              <Typography color={ThemeColor.b50} variant="badgeText">
                {t('account_name')}
              </Typography>
            </Column>
            <ButtonLink
              color={ComponentColors.primary}
              onClick={editItem}
              size="small"
            >
              {t('common.edit')}
            </ButtonLink>
          </Row>
        )}
        {iban && (
          <Column gap="4px">
            <Typography variant="p2Body">{iban}</Typography>
            <Typography color={ThemeColor.b50} variant="badgeText">
              IBAN
            </Typography>
          </Column>
        )}
      </Column>
    </CompanyCardItem>
  )
}

export const ProfilePaymentsList = ({
  companyId,
  name,
}: ProfilePaymentsListProps) => {
  const { t } = useTranslation()
  const { data, isLoading } = useCompanyPayments(companyId)
  const payments = ((data as unknown) as PaymentInfo[]) || []

  const addNewPayment = useGoToPath(paths.addPaymentWithKey(companyId))

  if (isLoading) return <Loader />

  return (
    <CompanyCard>
      <CompanyCardHeader>
        <ItemContainer>
          <Typography variant="captionR">{name}</Typography>
          <ButtonLink
            color={ComponentColors.primary}
            onClick={addNewPayment}
            size="small"
          >
            {t('add_common')}
          </ButtonLink>
        </ItemContainer>
      </CompanyCardHeader>
      <ShowWhen
        fallback={returns(
          <CompanyCardItem>
            <Typography color={ThemeColor.b50} variant="badgeText">
              {t('payment_details_missing')}
            </Typography>
          </CompanyCardItem>,
        )}
        when={!!payments.length}
      >
        {mapWithKey(PaymentItem, 'paymentId', payments)}
      </ShowWhen>
    </CompanyCard>
  )
}

export const ProfilePayments = () => {
  const { user } = useOutletContext<OutletContext>()
  const { t } = useTranslation()

  return (
    <>
      <PageMainContent>
        <BackComponent />
        <PageTitle>
          <Typography variant="h4">{t('profile.menu.payments')}</Typography>
        </PageTitle>
        <Column gap="16px">
          {mapWithKey(ProfilePaymentsList, 'companyId', user?.companies)}
        </Column>
      </PageMainContent>
      <PageWidgetContainer>
        <ContactExpert text={t('advisor_text_base')} />
      </PageWidgetContainer>
    </>
  )
}
