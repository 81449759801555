import {
  Absolute,
  BackComponent,
  CheckoutPriceCardBase,
  Column,
  ContactInfo,
  Image,
  MobileCheckoutPriceCardBase,
  ResponsivePageWidgetContainer,
  Row,
  SecondaryPageMainContent,
  ShowWhen,
  Typography,
} from '@/components'
import { Breakpoint, ThemeColor } from '@/enums'
import { useSegment } from '@/modules/analytics'
import { CheckoutStepperBreadcrumbs } from '@/modules/checkout/components/CheckoutStepperBreadcrumbs'
import { CheckoutStepKeys } from '@/pages/CheckoutPage/steps'
import { ContactContainer } from '@/pages/CheckoutPage/styles'
import { useContactModal } from '@/providers'
import {
  dynamicArrayPropType,
  dynamicObjectPropType,
  themeBreakpointDown,
  themeColor,
  themeTransition,
  useIsMobile,
  useToggable,
} from '@/utils'
import { Button } from '@surein/ui'
import { ArrowRight } from 'lucide-react'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { ifProp } from 'styled-tools'

const Contact = () => {
  const { t } = useTranslation()
  const { width } = useIsMobile()
  const isMobile = width <= 900
  return (
    <ContactContainer>
      <ContactInfo
        hide={{ chat: true, header: isMobile }}
        photo="chatblock"
        rowHeader
        subtitle={t('need_help')}
        title={t('contact_description')}
      />
    </ContactContainer>
  )
}

const StepperContainer = styled(Column)`
  gap: 24px;
  padding: 16px 24px;
  flex: 1;
  overflow-y: auto;

  ::-webkit-scrollbar {
    display: none;
  }
`

const StepWrapper = styled.div`
  flex: 1;
  overflow-y: auto;

  ::-webkit-scrollbar {
    display: none;
  }
`

const TopElementsWrapper = styled(Row)`
  > div {
    flex: 2;
  }

  > div:nth-child(2) {
    flex: 6;
  }

  ${themeBreakpointDown(Breakpoint.sm)} {
    > div {
      flex: unset;
    }
  }
`

const CheckoutStepperActions = styled(Row)`
  gap: 16px;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px 24px 24px;
  border-top: 1px solid ${themeColor(ThemeColor.b30)};
  z-index: 2;
  background-color: white;
  z-index: 12000;
  position: relative;
  border-radius: 0 0 24px 24px;

  > button:first-child {
    padding: 8px 16px 8px 8px;
  }

  @media (max-width: 900px) {
    flex-direction: column;
    border-radius: 0;
  }
`

const ContactButtonImage = styled(Image)`
  height: 32px;
  width: 32px;
  border-radius: 100%;
`

const Mask = styled(Absolute)`
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
  z-index: 11000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
  transition: ${themeTransition()};

  @media (max-width: 900px) {
    display: block;
    opacity: ${ifProp('shown', 1, 0)};
    visibility: ${ifProp('shown', 'visible', 'hidden')};
  }
`

const StepHeader = styled(Column)`
  gap: 8px;
  margin-bottom: 24px;
`

const ContactButtonIcon = () => (
  <ContactButtonImage name="chatblock" type="png" />
)

const getUniqueStepTitles = (steps) => {
  const hasKeyIndex = steps.some((step) => 'keyIndex' in step)

  if (!hasKeyIndex) {
    return steps.map((step) => step.title)
  }

  const uniqueSteps = steps.reduce((acc, step) => {
    if (!step.title) {
      return acc
    }

    if (!acc.some((s) => s.keyIndex === step.keyIndex)) {
      acc.push(step)
    }
    return acc
  }, [])

  return uniqueSteps.map((step) => step.title)
}

export const CheckoutBase = ({
  steps,
  data,
  buttonState,
  stepState,
  killerQuestionsState,
  children,
  isPublic,
}) => {
  const { t } = useTranslation()
  const { state } = useLocation()
  const { backPathname } = state || {}
  const { openContact, closeContact, isOpen: isContactOpen } = useContactModal()
  const { width } = useIsMobile()
  const isMobile = width <= 900
  const [isCheckoutDetailsOpen, setCheckoutDetailsOpen, toggle] = useToggable(
    false,
  )

  const analyticsPrefix = isPublic ? 'ssf' : 'app'

  const handleBack = () => {
    if (isContactOpen) {
      closeContact()
    } else if (stepState.isFirst && backPathname) {
      window.history.back()
    } else {
      stepState.back()
    }
  }

  const handleClickNext = () => {
    setCheckoutDetailsOpen(false)
    buttonState.onClick()
  }

  const analytics = useSegment()

  useEffect(() => {
    analytics.page({
      name: 'Page View',
      page_name: `${analyticsPrefix}_${stepState.step}`,
    })
  }, [stepState.step])

  return (
    <>
      <SecondaryPageMainContent>
        <Mask shown={isCheckoutDetailsOpen} />
        <StepperContainer>
          <ShowWhen when={!stepState.isLast}>
            <TopElementsWrapper>
              {!isPublic ||
              isContactOpen ||
              !stepState.isFirst ||
              backPathname ? (
                <BackComponent
                  noContentGutter
                  onBack={!stepState.isFirst && handleBack}
                />
              ) : (
                <div />
              )}
              <ShowWhen
                when={
                  !isContactOpen && !isMobile && !killerQuestionsState.isOpen
                }
              >
                <CheckoutStepperBreadcrumbs
                  current={steps[stepState.index].keyIndex ?? stepState.index}
                  steps={getUniqueStepTitles(steps)}
                />
              </ShowWhen>
              <div />
            </TopElementsWrapper>
          </ShowWhen>
          <StepWrapper>
            <StepHeader gap="8px">
              <Row gap="8px">
                <Typography bold variant="h4">
                  {isContactOpen
                    ? t('failed_answers_title')
                    : stepState.data.title}
                </Typography>
                {stepState.data?.tooltip}
              </Row>
              <ShowWhen when={isContactOpen || stepState.data.subtitle}>
                <Typography color={ThemeColor.b50} variant="p2Body">
                  {isContactOpen
                    ? t('failed_answers_subtitle')
                    : stepState.data.subtitle}
                </Typography>
              </ShowWhen>
            </StepHeader>
            <ShowWhen when={!isContactOpen}>{children}</ShowWhen>
          </StepWrapper>
        </StepperContainer>
        <ShowWhen when={!isContactOpen && !killerQuestionsState.isOpen}>
          <CheckoutStepperActions>
            <ShowWhen when={!isMobile}>
              <Button
                icon={ContactButtonIcon}
                onClick={openContact}
                variant="outline"
              >
                {t('common.contactUs')}
              </Button>
              <Button
                disabled={buttonState.disabled}
                loading={buttonState.loading}
                onClick={handleClickNext}
                reverted
                variant="primary"
                shape="square"
                suffixIcon={() => <ArrowRight size={18} />}
              >
                {stepState.index < steps.length - 2
                  ? t('next')
                  : t('confirmOrder')}
              </Button>
            </ShowWhen>
            <ShowWhen when={isMobile}>
              <MobileCheckoutPriceCardBase
                data={data}
                isCheckoutDetailsOpen={isCheckoutDetailsOpen}
                toggle={toggle}
              />
              <Button
                className="w-full"
                disabled={buttonState.disabled || buttonState.loading}
                loading={buttonState.loading}
                onClick={handleClickNext}
                reverted
                variant="primary"
                shape="square"
                suffixIcon={() => <ArrowRight size={18} />}
              >
                {stepState.index < steps.length - 2
                  ? t('next')
                  : t('confirmOrder')}
              </Button>
            </ShowWhen>
          </CheckoutStepperActions>
        </ShowWhen>
      </SecondaryPageMainContent>
      {!isMobile && (
        <ResponsivePageWidgetContainer
          dependencies={[
            stepState.step,
            isContactOpen,
            killerQuestionsState.isOpen,
          ]}
        >
          <ShowWhen
            when={
              !killerQuestionsState.isOpen && (!stepState.isLast || !isMobile)
            }
          >
            <ShowWhen
              fallback={Contact}
              when={!stepState.isLast && !isContactOpen}
            >
              <CheckoutPriceCardBase
                buttonDisabled={buttonState.disabled}
                buttonLoading={buttonState.loading}
                buttonText={buttonState.text}
                data={data}
                description={`${data[0]?.name} ${t('from')} ${
                  data[0]?.carrierName
                }`}
                hideProducts={
                  !isPublic && stepState.step === CheckoutStepKeys.overview
                }
              />
            </ShowWhen>
          </ShowWhen>
        </ResponsivePageWidgetContainer>
      )}
    </>
  )
}

CheckoutBase.propTypes = {
  buttonState: dynamicObjectPropType,
  children: PropTypes.node,
  data: dynamicArrayPropType,
  isPublic: PropTypes.bool,
  killerQuestionsState: dynamicObjectPropType,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      Component: PropTypes.elementType.isRequired,
      key: PropTypes.string.isRequired,
      subtitle: PropTypes.string,
      title: PropTypes.string,
    }),
  ).isRequired,
  stepState: dynamicObjectPropType,
}
