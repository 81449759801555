import { Categories } from '@/enums'

export interface InsuranceGroup {
  groupName: string
  keys: Array<keyof typeof Categories>
}

export const insuranceGroupsWithKeys: InsuranceGroup[] = [
  {
    groupName: 'mostPopular',
    keys: ['LI', 'FL', 'CO', 'LE', 'CA', 'CY'],
  },
  {
    groupName: 'propertyProtection',
    keys: [
      'EL',
      'BL',
      'PR',
      'BU',
      'WD',
      'GL',
      'HO',
      'RL',
      'SB',
      'CO',
      'RE',
      'AR',
      'MU',
    ],
  },
  {
    groupName: 'financialSecurity',
    keys: ['LO', 'LP', 'PO', 'EM', 'BS', 'IN'],
  },
  {
    groupName: 'liabilityAndLegal',
    keys: ['PL', 'HU', 'FL', 'LI', 'LE', 'DO', 'OL', 'ML', 'PF', 'HL', 'CL'],
  },
  {
    groupName: 'logisticsAndTransportation',
    keys: ['CA', 'TR', 'BI', 'CR', 'TA'],
  },
  {
    groupName: 'pension',
    keys: ['BP', 'BC', 'DI', 'PP', 'PE', 'AN', 'RP', 'PC', 'ST', 'RI'],
  },
  {
    groupName: 'healthAndWellness',
    keys: [
      'HI',
      'LF',
      'DS',
      'SU',
      'DH',
      'OD',
      'BA',
      'DR',
      'DB',
      'HE',
      'SH',
      'CH',
      'SP',
      'LU',
      'LL',
      'LC',
      'TL',
      'NC',
      'DN',
      'DE',
    ],
  },
  {
    groupName: 'travel',
    keys: ['TH', 'BG', 'TC', 'CP'],
  },
  {
    groupName: 'animal',
    keys: ['AO', 'AH'],
  },
  {
    groupName: 'specializedProtection',
    keys: [
      'CY',
      'EV',
      'EX',
      'FU',
      'CM',
      'WA',
      'CC',
      'AI',
      'AP',
      'AM',
      'MI',
      'TE',
      'AL',
      'SV',
      'OT',
      'VSV',
    ],
  },
]
