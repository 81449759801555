import { FormTextarea } from '@/components'
import { AssessmentStepKeys } from '@/enums'
import {
  FormContent,
  StepContainer,
  StepFooter,
  StepHeader,
} from '@/modules/yearlyCheck/components/steps/components'
import { useStepper } from '@/modules/yearlyCheck/hooks/useStepper'
import { useSaveAssessmentStep } from '@/pages/SelfAssessmentPage/helpers'
import { FormProvider, useFormContext } from '@/providers'
import { useTranslation } from 'react-i18next'
import { useSegment } from '@/modules/analytics'

const NotesForm = () => {
  const { onNext } = useStepper()
  const { values } = useFormContext()
  const { t } = useTranslation()
  const analytics = useSegment()

  const { save, isLoading: isButtonLoading } = useSaveAssessmentStep(
    'notes',
    (value) => value.notes,
    true,
  )

  const handleOnNoteSubmit = () => {
    if (!values) return

    save().then(async () => {
      await analytics.track('user_app_yearly_check_additional_note')
      onNext(AssessmentStepKeys.notes, values.notes)
    })
  }

  return (
    <>
      <StepHeader
        description={t('yearlyAssessment.additionalNote-description')}
        sectionLabel={t('yearlyAssessment.additionalNote-category')}
        title={t('yearlyAssessment.additionalNote-title')}
      />
      <FormContent>
        <FormTextarea
          name="notes.body"
          placeholder={t('yearlyAssessment.additionalNote-placeholder')}
          rows="6"
        />
      </FormContent>
      <StepFooter
        isButtonLoading={isButtonLoading}
        onSubmit={handleOnNoteSubmit}
      />
    </>
  )
}

export const AdditionalNote = () => {
  const { formValues } = useStepper()

  return (
    <StepContainer>
      <FormProvider
        initialValues={{
          notes: {
            body: formValues.notes?.body || '',
          },
        }}
      >
        <NotesForm />
      </FormProvider>
    </StepContainer>
  )
}
