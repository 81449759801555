/* eslint-disable no-nested-ternary */
import { ThemeColor } from '@/enums'
import { autoRecommendationCategories } from '@/pages/RequestOffer/helpers'
import { useSnackbar } from '@/providers'
import { paths } from '@/routes/paths'
import { useLocationHome, useRequestRecommendation } from '@/services'
import { theme } from '@/theme'
import { useGoToPath, useItemDialog, useSearchParams } from '@/utils'
import { Button } from '@surein/ui'
import { Check, Upload } from 'lucide-react'
import { allPass, prop, propEq } from 'ramda'
import { useParams } from 'react-router-dom'
import {
  CategoryIcon,
  FilePicker,
  Loader,
  Row,
  ShowWhen,
  Typography,
} from '../../../components'
import { checkIfRecommendationExists } from '../../LocationPage/helpers'
import { UploadContractModal } from '../UploadContractModal'
import { useMessages } from '../helpers'
import {
  DefaultInsuranceDetails,
  HeaderDetails,
  InsuranceDefaultViewWrapper,
  PageActions,
  UspsContainer,
} from '../styles'
import { useSegment } from '@/modules'

export const InsuranceDefaultView = () => {
  const { recommendationId } = useSearchParams()
  const { categoryId, locationId } = useParams()
  const snackbars = useSnackbar()
  const messages = useMessages(categoryId)
  const { data, isLoading } = useLocationHome(locationId)
  const requestOfferMutation = useRequestRecommendation(locationId)
  const {
    item: files,
    openItemDialog,
    isOpen,
    closeItemDialog,
  } = useItemDialog()
  const goToLocation = useGoToPath(paths.viewLocationWithKey(locationId))

  const goToRecommendation = useGoToPath(paths.viewRecommendationWithKey)
  const goToRequestOffer = useGoToPath(paths.requestOfferWithKey)

  const analytics = useSegment()

  const handleRequestOffer = async () => {
    await analytics.track('app_request_offer', {
      categoryId,
      source: 'insurance_finder',
    })

    if (autoRecommendationCategories.includes(categoryId)) {
      goToRequestOffer({
        categoryId,
        locationId,
      })
      return
    }

    try {
      await requestOfferMutation.mutateAsync({ categoryId })
      await analytics.track('app_request_offer_failed', {
        categoryId,
      })
      snackbars.success(messages.requestSuccess)
      goToLocation()
    } catch (error) {
      console.error(error)
      snackbars.error(messages.error)
    }
  }

  if (isLoading) return <Loader />

  const sent = data?.recommendations.find(
    allPass([
      prop('sended'),
      prop('lowestPrice'),
      propEq(categoryId, 'categoryId'),
    ]),
  )

  return (
    <InsuranceDefaultViewWrapper>
      <DefaultInsuranceDetails>
        <HeaderDetails>
          <Row alignItems="center" gap="8px">
            <CategoryIcon categoryId={categoryId} />
            <Typography responsive variant="h4">
              {messages.categoryName}
            </Typography>
          </Row>
          <Typography color={ThemeColor.b50} variant="p1Body">
            {messages.longDesc}
          </Typography>
        </HeaderDetails>
        <ShowWhen when={messages.usps.length}>
          <UspsContainer>
            {messages.usps.map((item) => (
              <Row key={item} alignItems="center" gap="16px">
                <Check color={theme.color.info1} />
                <Typography variant="p1Body">{item}</Typography>
              </Row>
            ))}
          </UspsContainer>
        </ShowWhen>
        <PageActions>
          <FilePicker accept=".pdf,.jpg" onPick={openItemDialog}>
            <Button variant="muted" shape="square" suffixIcon={Upload}>
              {messages.uploadExistingContract}
            </Button>
          </FilePicker>
          {!checkIfRecommendationExists(data, categoryId) ? (
            <Button
              variant="primary"
              shape="square"
              loading={requestOfferMutation.isLoading}
              onClick={handleRequestOffer}
            >
              {messages.requestOffer}
            </Button>
          ) : sent ? (
            <Button
              variant="primary"
              shape="square"
              onClick={() =>
                goToRecommendation(recommendationId || sent.recommendationId)
              }
            >
              {messages.openOffer}
            </Button>
          ) : (
            <Button shape="square" disabled>
              {messages.offerInProgress}
            </Button>
          )}
        </PageActions>
      </DefaultInsuranceDetails>
      {isOpen && (
        <UploadContractModal files={files} onClose={closeItemDialog} />
      )}
    </InsuranceDefaultViewWrapper>
  )
}
