import { CarrierLogo, Row, Typography } from '@/components'
import { ThemeColor } from '@/enums'
import { ArrowRight } from '@/icons'
import { getYearlyPriceFromGrossPrice } from '@/modules/price/price'
import { paths } from '@/routes/paths'
import { theme } from '@/theme'
import { ddMMyyyy, formatMoney, useGoToPath } from '@/utils'
import {
  ColumnDef,
  DataTable,
  SortableHeader,
  TableOptions,
  createColumnHelper,
  useDataTable,
} from '@surein/ui'
import { ChevronRightIcon, Coffee, Zap } from 'lucide-react'
import { isNotNil, pick } from 'ramda'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Insurance } from './Page'
import { StatusBadge } from './StatusBadge'
import { ContractStatus, dateSortingFn, getCategoryName } from './helpers'
import {
  EmptyStateContainer,
  InsuranceEntryTextContainer,
  InsuranceEntryWrapper,
  InsuranceFinderButton,
  LocationTableRightPrice,
  TableContainer,
  TableGrossPrice,
  TableScrollWrapper,
} from './styles'

const columnHelper = createColumnHelper<Insurance>()

const createColumns = (
  locationId: string,
  getLocationLabel: ((code: string) => string) | undefined,
  t: (key: string, options?: Record<string, boolean>) => string,
): ColumnDef<Insurance, unknown>[] =>
  [
    columnHelper.accessor('carrierName', {
      header: ({ column }) => (
        <SortableHeader column={column}>{t('insurance')}</SortableHeader>
      ),
      cell: ({ row }) => {
        const props = pick(
          ['categoryId', 'insuranceId', 'logoUrl', 'category'],
          row.original,
        ) as Pick<
          ContractsTableInsuranceEntryProps,
          'categoryId' | 'insuranceId' | 'logoUrl' | 'category'
        >
        return (
          <ContractsTableInsuranceEntry locationId={locationId} {...props} />
        )
      },
      sortingFn: (rowA, rowB) => {
        return rowA.original.category.localeCompare(rowB.original.category)
      },
      size: 280,
    }),
    columnHelper.accessor('contractPaymentNextDate', {
      header: ({ column }) => (
        <SortableHeader column={column}>{t('nextPayment')}</SortableHeader>
      ),
      cell: (contractPaymentNextDate) => {
        const value = contractPaymentNextDate.getValue()
        return value ? ddMMyyyy(Date.parse(value)) : '-'
      },
      sortingFn: dateSortingFn,
      size: 110,
    }),
    columnHelper.accessor('risksInsured', {
      header: ({ column }) => (
        <SortableHeader column={column}>{t('information')}</SortableHeader>
      ),
      cell: (risksInsured) => {
        const value = risksInsured.getValue() || '-'
        const riskInsured = getLocationLabel?.(value) || value

        return <Typography variant="p2Body">{riskInsured}</Typography>
      },
      enableGlobalFilter: true,
      size: 180,
    }),
    columnHelper.accessor('policyNumber', {
      header: ({ column }) => (
        <SortableHeader column={column}>{t('insuranceNumber')}</SortableHeader>
      ),
      cell: (policyNumber) => (
        <Typography
          title={policyNumber.getValue() ?? undefined}
          variant="p2Body"
        >
          {policyNumber.getValue() || '-'}
        </Typography>
      ),
    }),
    columnHelper.accessor('grossPrice', {
      header: ({ column }) => (
        <SortableHeader column={column}>{t('cost')}</SortableHeader>
      ),
      cell: ({ row }) => {
        const price = Number(row.original.grossPrice)
        const { paymentPeriod } = row.original
        const paymentPeriodOptions = t('paymentPeriodNouns', {
          returnObjects: true,
        })
        const translatedPaymentPeriod = paymentPeriod
          ? `/ ${
              paymentPeriodOptions[
                paymentPeriod as keyof typeof paymentPeriodOptions
              ]
            }`
          : ''

        return (
          <TableGrossPrice>
            {isNotNil(price)
              ? `${formatMoney(price)} ${translatedPaymentPeriod}`
              : '-'}
          </TableGrossPrice>
        )
      },
    }),
    columnHelper.accessor('contractStatus', {
      header: ({ column }) => (
        <SortableHeader column={column}>{t('contractStatus')}</SortableHeader>
      ),
      cell: (contractStatus) =>
        contractStatus.getValue() ? (
          <StatusBadge status={contractStatus.getValue<ContractStatus>()} />
        ) : (
          '-'
        ),
      sortingFn: dateSortingFn,
      size: 110,
    }),
    columnHelper.accessor('locationName', {
      cell: (locationName) => locationName.getValue() || '-',
    }),
    columnHelper.accessor('companyName', {
      cell: (locationName) => locationName.getValue() || '-',
    }),
    columnHelper.accessor('category', {
      cell: (category) => category.getValue() || '-',
    }),
  ] as Array<ColumnDef<Insurance, unknown>>

interface AggregatedInsurance extends Insurance {
  locationName: string
  companyName: string
  policyNumber: string
  category: string
}

interface LocationTableProps {
  companyId: string
  companyName: string
  grossPrice: number
  insurances: Insurance[]
  locationId: string
  name: string
  search?: string
  getLocationLabel: ((code: string) => string) | undefined
  archivedView: boolean
}

interface TableEmptyStateProps {
  locationId: string
}

const TableEmptyStateActive = ({ locationId }: TableEmptyStateProps) => {
  const { t } = useTranslation()
  const goToFindInsurances = useGoToPath(
    paths.findInsurancesWithKey(locationId),
  )

  return (
    <EmptyStateContainer>
      <Zap size={32} color={theme.color.glass300} />
      <Typography color={ThemeColor.glass500} variant="p2Body">
        {t('noActiveContracts')}
      </Typography>
      <InsuranceFinderButton onClick={goToFindInsurances}>
        <Typography bold color={ThemeColor.electric500} variant="p2Body">
          {t('findProtection')}
        </Typography>
        <ChevronRightIcon color={theme.color.electric500} size={16} />
      </InsuranceFinderButton>
    </EmptyStateContainer>
  )
}

const TableEmptyStateArchived = () => {
  const { t } = useTranslation()

  return (
    <EmptyStateContainer>
      <Coffee size={32} color={theme.color.glass300} />
      <Typography color={ThemeColor.glass500} variant="p2Body">
        {t('noArchivedContracts')}
      </Typography>
    </EmptyStateContainer>
  )
}

const LocationTable = ({
  name,
  insurances,
  companyName,
  locationId,
  search,
  getLocationLabel,
  archivedView,
}: LocationTableProps) => {
  const { t } = useTranslation()

  const columns = useMemo(
    () => createColumns(locationId, getLocationLabel, t),
    [locationId, getLocationLabel, t],
  )

  const aggregatedData: AggregatedInsurance[] = useMemo(
    () =>
      insurances.map((insurance) => ({
        ...insurance,
        locationName: name,
        companyName,
        policyNumber: insurance.policyNumber || '',
        category: getCategoryName(insurance.categoryId, t),
      })),
    [JSON.stringify(insurances), name, companyName],
  )

  const tableConfigOverrides: Partial<TableOptions<Insurance>> = useMemo(
    () => ({
      renderFallbackValue: '-',
      initialState: {
        columnVisibility: {
          locationName: false,
          companyName: false,
          category: false,
        },
      },
      state: {
        globalFilter: search,
      },
      enableFilters: true,
      enableColumnFilters: true,
      globalFilterFn: 'includesString',
    }),
    [search],
  )

  const table = useDataTable<Insurance>({
    columns: columns,
    data: aggregatedData,
    configOverrides: tableConfigOverrides,
  })

  const visibleGrossPrice = table
    .getFilteredRowModel()
    .flatRows.map((row) => row.original)
    .reduce(
      (sum, item) =>
        sum +
        getYearlyPriceFromGrossPrice(
          item.grossPrice ?? 0,
          item.paymentPeriod || 'yearly',
        ),
      0,
    )

  const hasVisibleRows = Boolean(table.getFilteredRowModel().flatRows.length)

  return (
    <TableContainer>
      <Row gap="16px" justifyContent="space-between">
        <Typography bold variant="p1Body">
          {name}
        </Typography>
        <LocationTableRightPrice alignItems="center" gap="4px">
          <Typography color={ThemeColor.b40} variant="badgeText">
            {t('total')}
          </Typography>
          <Typography color={ThemeColor.b50} variant="p2Body">
            {formatMoney(visibleGrossPrice || 0)}
            <Typography color={ThemeColor.b40} variant="badgeText">
              {` / ${t('year')}`}
            </Typography>
          </Typography>
        </LocationTableRightPrice>
      </Row>
      <TableScrollWrapper isEmpty={!hasVisibleRows}>
        <DataTable table={table} borderless emptyText={t('empty')} />
        {!hasVisibleRows &&
          (archivedView ? (
            <TableEmptyStateArchived />
          ) : (
            <TableEmptyStateActive locationId={locationId} />
          ))}
      </TableScrollWrapper>
    </TableContainer>
  )
}

export default LocationTable

interface ContractsTableInsuranceEntryProps {
  category: string
  categoryId: string
  insuranceId: string
  locationId: string
  logoUrl: string
}

const ContractsTableInsuranceEntry = ({
  categoryId,
  category,
  insuranceId,
  locationId,
  logoUrl,
}: ContractsTableInsuranceEntryProps) => {
  const goToInsurance = useGoToPath(
    paths.viewInsuranceWithKey({ categoryId, insuranceId, locationId }),
  )

  return (
    <InsuranceEntryWrapper onClick={goToInsurance}>
      <CarrierLogo height="32px" name={logoUrl} width="32px" />
      <InsuranceEntryTextContainer>
        <Row alignItems="center">
          <Typography bold title={category} variant="badgeText">
            {category}
          </Typography>
          <ArrowRight size="message" />
        </Row>
      </InsuranceEntryTextContainer>
    </InsuranceEntryWrapper>
  )
}
