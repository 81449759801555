import { Badge } from '@surein/ui'
import {
  AlertCircle,
  Archive,
  BarChart2,
  Loader,
  LucideIcon,
  Pause,
  X,
} from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { ContractStatus } from './helpers'

type StatusBadgeProps = {
  status: ContractStatus
}

const IconToStatusMap: Record<ContractStatus, LucideIcon> = {
  aktiv: BarChart2,
  'transfer-initiated': BarChart2,
  antrag: Loader,
  review: Loader,
  validated: Loader,
  ablauf: Archive,
  storniert: X,
  ruhend: Pause,
  ablehnung: AlertCircle,
}

const StatusToVariantMap: Record<ContractStatus, string> = {
  aktiv: 'success',
  'transfer-initiated': 'success',
  antrag: 'warning',
  review: 'warning',
  validated: 'warning',
  ablauf: 'default',
  storniert: 'warning',
  ruhend: 'default',
  ablehnung: 'warning',
}

const InternalStatusToCustomerStatusMap: Record<
  ContractStatus,
  ContractStatus
> = {
  aktiv: 'aktiv',
  'transfer-initiated': 'aktiv',
  antrag: 'antrag',
  review: 'antrag',
  validated: 'antrag',
  ablauf: 'ablauf',
  storniert: 'storniert',
  ruhend: 'ruhend',
  ablehnung: 'ablehnung',
}

export const StatusBadge = ({ status }: StatusBadgeProps) => {
  const { t } = useTranslation()

  return (
    <Badge
      size="lg"
      isRounded
      variant={StatusToVariantMap[status]}
      icon={IconToStatusMap[status]}
    >
      {t(`contractStatuses.${InternalStatusToCustomerStatusMap[status]}`)}
    </Badge>
  )
}
