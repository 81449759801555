import { UserProfile, UserProfileCompany } from '@/types/user-profile'
import { useState } from 'react'

export const isUserMD = (data: UserProfile) =>
  data ? !!data?.companies.find((el) => el?.userType?.MD) : false

export const hasCompaniesPoASigned = (data: UserProfile) =>
  data
    ? !!data?.companies.find(
        ({ poaSigned, userType }) => poaSigned && userType.MD,
      )
    : false

export const useSteps = (initStep: number, max: number) => {
  const [step, setStep] = useState(initStep || 1)

  const nextStep = () => {
    setStep(step < max ? step + 1 : max)
  }
  const prevStep = () => {
    setStep(step > 1 ? step - 1 : 1)
  }
  const isActive = (n: number) => n === step
  return {
    step,
    nextStep,
    prevStep,
    isActive,
    setStep,
  }
}

export const makeBankTranslation = (
  language: 'en' | 'de',
  bankName: string,
) => {
  if (language === 'en' && bankName) {
    return bankName
  }
  if (language === 'de' && bankName) {
    return `${bankName}-`
  }
  return ''
}

export const getCanEditCompany = (
  userCompanies: UserProfileCompany[],
  companyId: string,
) => {
  const company = userCompanies.find((el) => el.companyId === companyId)
  const canEditCompany = company?.userType?.MD || company?.userType?.owner
  return canEditCompany
}
