import { Breakpoint, ThemeColor } from '@/enums'
import {
  pixelsFromProp,
  themeBreakpointDown,
  themeTransition,
  toThemePixels,
} from '@/utils'
import { pipe } from 'ramda'
import styled, { css, keyframes } from 'styled-components'
import { ifNotProp, ifProp, prop, switchProp } from 'styled-tools'
import { placeholderStyles } from '../atoms/Typography/styles'

const showMask = keyframes`
  from {
    opacity: 0;
  }
`

/* PLOP_INJECT_EXPORT */

export const WholeWidth = styled.div`
  width: 100%;
`

export const pageWrapperPaddings = css`
  background-color: ${(props) => props.theme.color.b0};

  ${themeBreakpointDown(Breakpoint.tab)} {
    padding: ${toThemePixels(10)} ${toThemePixels(2.5)} ${toThemePixels(35)};
  }
  ${themeBreakpointDown(Breakpoint.sm)} {
    padding: ${toThemePixels(10)} 10px;
  }
`

export const Absolute = styled.div`
  position: absolute;
  top: ${prop('top')};
  bottom: ${prop('bottom')};
  left: ${prop('left')};
  right: ${prop('right')};
  z-index: ${prop('zIndex')};
  height: ${prop('height')};
`

export const Fixed = styled.div<{
  top?: number
  bottom?: number
  left?: number
  right?: number
  zIndex?: number
}>`
  position: fixed;
  top: ${prop('top')};
  bottom: ${prop('bottom')};
  left: ${prop('left')};
  right: ${prop('right')};
  z-index: ${prop('zIndex')};
`

export const Paper = styled.div`
  background: ${(props) => props.theme.color.b0};
  box-shadow: 0px 0px 15px rgba(133, 182, 255, 0.2);
  border-radius: ${toThemePixels(6)};
`

export const Relative = styled.div`
  position: relative;
`

const absoluteMaskStyles = css`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Mask = styled.div`
  z-index: 100000;
  position: ${ifProp('absolute', 'absolute', 'fixed')};
  top: 0;
  left: 0;
  height: -webkit-fill-available;
  height: 100dvh;
  width: 100vw;
  background-color: ${(props) => props.theme.color.b0}40;
  animation: ${ifProp('noAnimation', 'none', showMask)} 400ms;
  backdrop-filter: blur(4px);
  ${ifProp('absolute', absoluteMaskStyles)}
`

export const Indent = styled.div`
  margin-left: ${prop('indent')}px;
  margin-top: ${toThemePixels(5)};
  width: calc(100% - ${prop('indent')}px);
`

export const Alignment = styled.div`
  display: ${switchProp('alignment', {
    vertical: 'flex',
    horizontal: 'flex',
    grid: 'grid',
  })};
  grid-template-columns: repeat(${prop('columns')}, 1fr);
  flex-direction: ${switchProp('alignment', {
    vertical: 'column',
    horizontal: 'row',
  })};
  flex-wrap: ${ifProp('wrap', 'wrap')};
  width: 100%;
  gap: ${toThemePixels(5)};
`

export const ListItem = styled.li`
  &::marker {
    color: ${(props) => props.theme.color.info1};
    font-size: ${toThemePixels(4)};
  }
`

/**
 * TODO: fix types
 */
export const Flex = styled.div<any>`
  display: flex;
  flex-direction: ${prop('flexDirection')};
  flex: ${prop('flex')};
  justify-content: ${prop('justifyContent')};
  align-items: ${prop('alignItems')};
  gap: ${prop('gap')};
  width: ${prop('width')};
  max-width: ${prop('maxWidth')};
  min-width: ${prop('minWidth')};
  flex-wrap: ${prop('wrap')};
`

const publicPageLayout = css`
  background-color: ${(props) => props.theme.color.b0};
`

export const PageLayout = styled.div`
  height: auto;
  display: flex;
  flex-direction: column;
  background-color: ${ifProp('dark', prop('theme.color.b10'))};
  height: auto;
  flex: 1;
  min-height: ${ifProp('dark', '100vh')};
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;

  ${ifProp('simple', publicPageLayout)};

  @media (max-width: 1160px) {
    min-height: ${ifProp('dark', '100vh', 'calc(100vh - 60px - 16px - 24px)')};
  }
`

export const PageWrapper = styled.div`
  display: flex;
  height: auto;
  flex: 1;
`

export const PageLayoutColumn = styled.div`
  height: 100%;

  @media (max-width: 980px) {
    min-height: unset;
    overflow-y: visible;
  }
`

export const PageMainContent = styled(PageLayoutColumn)<{ dark?: boolean }>`
  flex: 1;
  padding: 0 32px;
  min-width: 0;
  background-color: ${ifProp(
    'dark',
    prop('theme.color.b10'),
    prop('theme.color.b0'),
  )};
  ::-webkit-scrollbar {
    display: none;
  }

  ${themeBreakpointDown(Breakpoint.sm)} {
    padding: 0 16px;
  }
`

export const PageWidgetContainer = styled(PageLayoutColumn)`
  flex: 0 1 30%;
  height: auto;
  padding-right: ${toThemePixels(6)};

  > * {
    position: sticky;
    top: 76px;
  }

  @media (max-width: 1160px) {
    padding-left: 0;
    padding-right: 0;
  }

  ${themeBreakpointDown(Breakpoint.xl)} {
    display: ${ifNotProp('showOnMobile', 'none')};
  }

  @media (max-width: 655px) {
    min-height: unset;
    height: max-content;
    flex: 0 1 100%; /* Take full width on very small screens if visible */
  }
`

export const SecondaryPageWrapper = styled(PageLayoutColumn)`
  display: flex;
  flex: 1;
  padding: 0 64px;
  background-color: ${prop('theme.color.b10')};
  ::-webkit-scrollbar {
    display: none;
  }

  ${themeBreakpointDown(Breakpoint.md)} {
    padding: 0;
  }
`

export const SecondaryPageMainContent = styled(PageLayoutColumn)`
  display: flex;
  flex-direction: column;
  align-self: center;
  margin: 0 auto;
  flex: 1;
  background-color: ${(props) => props.theme.color.b0};
  border-radius: ${toThemePixels(6)};
  height: calc(100vh - 108px);
  margin: 24px 80px;

  ::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 655px) {
    grid-column: col-start / span 2;
  }

  ${themeBreakpointDown(Breakpoint.md)} {
    margin: 0;
    height: calc(100vh - 60px);
    height: calc(100dvh - 60px);
  }

  ${themeBreakpointDown(Breakpoint.sm)} {
    margin: 0;
    border-radius: 0;
  }
`

export const SecondaryPageWidgetContainer = styled(PageLayoutColumn)`
  grid-column: col-start 9 / span 4;
  margin-top: -${toThemePixels(4)};
  padding-top: ${toThemePixels(4)};
  flex: 0 1 30%;
  height: calc(100% + 16px);

  > * {
    position: sticky;
    top: 76px;
  }

  @media (max-width: 900px) {
    border: none;
    grid-column: col-start 2 / span 10;
  }

  @media (max-width: 655px) {
    grid-column: col-start / span 2;
  }

  ${themeBreakpointDown(Breakpoint.sm)} {
    margin-top: ${toThemePixels(2)};

    :has(*) {
      position: relative;
    }

    > * {
      position: absolute;
      bottom: 0;
      left: -${toThemePixels(6)};
      right: -${toThemePixels(6)};
      top: unset;
    }
  }
`

export const PublicPageContent = styled(PageLayoutColumn)`
  grid-column: col-start / span 2;
`

export const Square = styled.div`
  width: ${pixelsFromProp('$size')};
  min-width: ${pixelsFromProp('$size')};
  height: ${pixelsFromProp('$size')};
`
export const Row = styled(Flex).attrs({ flexDirection: 'row' })``

export const Column = styled(Flex).attrs({ flexDirection: 'column' })``

export const WithGap = styled(Column)`
  gap: ${pipe(prop('gap'), toThemePixels)};
`

export const FlexEnd = styled(Row).attrs({ justifyContent: 'flex-end' })`
  margin-bottom: ${toThemePixels(5)};
`

const eventStyles = css<{ error?: boolean }>`
  &:hover {
    border: 1px solid ${(props) => props.theme.color.b40};
  }

  &:focus {
    color: ${(props) => props.theme.color.b100};
    -webkit-text-fill-color: ${(props) => props.theme.color.b100};
    border: 1px solid ${(props) => props.theme.color.b100};
  }
`

export const inputBaseStyles = css<{ error?: boolean; rounded?: boolean }>`
  outline: none;
  user-select: text;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 52px;
  width: inherit;
  min-width: 200px;
  padding: ${toThemePixels(4.5)};
  border-radius: ${ifProp('rounded', toThemePixels(3), toThemePixels(2))};

  background: ${ifProp(
    'error',
    prop('theme.color.danger4'),
    prop('theme.color.b0'),
  )};
  border: 1px solid
    ${ifProp('error', prop('theme.color.danger2'), prop('theme.color.b30'))};

  color: ${(props) => props.theme.color.b100};
  -webkit-text-fill-color: ${(props) => props.theme.color.b100};
  box-sizing: border-box;

  transition: ${themeTransition()};

  ${placeholderStyles}
  &::placeholder {
    color: ${(props) => props.theme.color.b40};
    -webkit-text-fill-color: ${(props) => props.theme.color.b40};
    ${placeholderStyles}
    white-space: nowrap;
  }

  ${ifNotProp('error', eventStyles)}

  &:disabled {
    border: 1px solid transparent;
    ${placeholderStyles}
  }
`

const noArrowStyles = css`
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }
`

export const Input = styled.input`
  ${inputBaseStyles}

  ${ifProp('hideArrows', noArrowStyles)}
`
export const TextArea = styled.textarea`
  ${inputBaseStyles}
  height: auto;
`
export const EditPageWrapper = styled(Column)`
  align-items: center;
  justify-content: flex-start;
  margin: 0 auto;
  width: 410px;
  min-height: calc(100vh - 60px - 16px);
  background-color: ${(props) => props.theme.color.b0};
  gap: ${toThemePixels(4)};
  ${pageWrapperPaddings}

  ${themeBreakpointDown(Breakpoint.sm)} {
    width: 100vw;
    padding: ${toThemePixels(21)} ${toThemePixels(4)} ${toThemePixels(35)};
  }
`
export const PageActionsStyles = styled(Row)`
  margin-top: ${toThemePixels(3)};
  gap: ${toThemePixels(2)};
  justify-content: space-between;
  max-width: 100%;
  ${themeBreakpointDown(Breakpoint.sm)} {
    flex-wrap: wrap-reverse;
    justify-content: center;
    button {
      width: 100%;
    }
  }
`

const reversedInputGroupStyles = css`
  input:first-child {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }

  input:last-child {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }

  > *:last-child {
    width: 110px;
    input {
      min-width: 110px;
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
      border-bottom-right-radius: ${toThemePixels(1)};
      border-top-right-radius: ${toThemePixels(1)};
    }
  }
`
const inputGroupStyles = css`
  > *:first-child {
    width: 110px;
    input {
      min-width: 110px;
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }
  }

  input:first-child {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }

  input:last-child {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
`

export const GroupInputContainer = styled(Row)`
  width: 100%;

  ${ifProp('reversed', reversedInputGroupStyles, inputGroupStyles)};
`

export const cardMobileStyles = css`
  ${themeBreakpointDown(Breakpoint.sm)} {
    width: 100vw;
    padding: ${toThemePixels(10)};
  }
`

export const cardWithoutBorderStyles = css`
  ${themeBreakpointDown(Breakpoint.sm)} {
    border-radius: 0;
    box-shadow: none;
  }
`

export const darkCardStyles = css<{ dark?: boolean }>`
  background-color: ${(props) => props.theme.color.b10};
  border: 1px solid ${(props) => props.theme.color.b30};
`

export const Card = styled.div`
  background-color: ${(props) => props.theme.color.b0};
  border-radius: ${toThemePixels(3)};
  padding: ${ifProp('padding', prop('padding'), '40px 40px 32px 40px')};
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.06);

  ${ifProp('responsive', cardMobileStyles)}

  ${ifProp('withoutBorder', cardWithoutBorderStyles)}

  ${ifProp('dark', darkCardStyles)}
`

export const Divider = styled.div<{
  marginBottom?: string
  marginTop?: string
  color?: keyof typeof ThemeColor
}>`
  width: 100%;
  height: 1px;
  flex-shrink: 0;
  background-color: ${(props) => props.theme.color[props.color || 'b50']};
  margin-top: ${ifProp('marginTop', prop('marginTop'), 'unset')};
  margin-bottom: ${ifProp('marginBottom', prop('marginBottom'), 'unset')};
`

export const CompanyCard = styled.div`
  width: 100%;
  border: 1px solid ${(props) => props.theme.color.b30};
  border-radius: ${toThemePixels(3)};
  overflow: hidden;
`

export const CompanyCardHeader = styled(Row)`
  padding: ${toThemePixels(4)};
  background: linear-gradient(90deg, #e6e8ff 0%, #f5f6ff 100%);
  gap: ${toThemePixels(6)};
`

export const CompanyCardItem = styled(Row)`
  padding: ${toThemePixels(4)};
  border-top: 1px solid ${(props) => props.theme.color.b30};
  gap: ${toThemePixels(6)};

  > * {
    width: 100%;
  }
`

export const TextWithLink = styled.div<{
  fontSize?: string
  color?: string
  centerd?: boolean
}>`
  width: 100%;
  font-size: ${ifProp('fontSize', prop('fontSize'), toThemePixels(3))};
  color: ${ifProp('color', prop('color'), prop('theme.color.b50'))};
  text-align: ${ifProp('centered', 'center')};

  button {
    display: inline-block;
    height: fit-content;

    span {
      font-size: inherit;
    }
  }
`

export const DividerWithText = styled(Row)`
  padding: ${toThemePixels(4.5)} ${toThemePixels(3)};
  position: relative;
  width: 100%;
  justify-content: center;

  > span {
    padding: 0 ${toThemePixels(5)};
    background-color: ${(props) => props.theme.color.b0};
    z-index: 1;
  }

  &::after {
    content: '';
    position: absolute;
    height: 2px;
    left: ${toThemePixels(3)};
    right: ${toThemePixels(3)};
    top: calc(50% - 1px);
    z-index: 0;
    background-color: ${(props) => props.theme.color.b30};
  }
`
