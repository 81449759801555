import { Breakpoint, Categories, ThemeColor } from '@/enums'
import { paths } from '@/routes/paths'
import {
  makeQueryString,
  propTypeFromEnumKeys,
  useGoToPath,
  useIsMobile,
} from '@/utils'
import { Button } from '@surein/ui'
import { ArrowRight } from 'lucide-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Typography } from '../../atoms'
import { ShowWhen } from '../../hoc/ShowWhen'
import { Column, Row } from '../../styled'
import {
  ButtonContainer,
  Container,
  NewTag,
  RecommendationDetails,
  Tag,
} from './styles'

export const RecommendationCardBase = ({
  categoryId,
  primaryDisabled,
  primaryText,
  important,
  isNew,
  onCardClick,
  onButtonClick,
  inlineButton,
}) => {
  const categoryData = Categories[categoryId]
  const { t } = useTranslation()

  const handleButtonClick = (e) => {
    e.stopPropagation()
    onButtonClick()
  }

  return (
    <Container onClick={onCardClick}>
      <Row gap="24px" justifyContent="space-between" width="100%">
        <RecommendationDetails>
          <ShowWhen when={important}>
            <Tag>
              {isNew && (
                <NewTag>
                  <Typography color={ThemeColor.b0} variant="micro">
                    {t('new')}
                  </Typography>
                </NewTag>
              )}
              <Typography variant="captionR">
                {t('insuranceStages.important')}
              </Typography>
            </Tag>
          </ShowWhen>
          {isNew && !important && (
            <NewTag>
              <Typography color={ThemeColor.b0} variant="micro">
                {t('new')}
              </Typography>
            </NewTag>
          )}
          <Column gap="4px">
            <Typography variant="captionB">
              {t(`categories.${categoryData.key}.name`)}
            </Typography>
            <Typography color={ThemeColor.b50} variant="p2Body">
              {t(`categories.${categoryData.key}.shortDesc`)}
            </Typography>
          </Column>
          {!inlineButton && (
            <ButtonContainer>
              <Button
                variant="primary"
                shape="square"
                disabled={primaryDisabled}
                onClick={handleButtonClick}
                suffixIcon={!primaryDisabled && ArrowRight}
              >
                {primaryText}
              </Button>
            </ButtonContainer>
          )}
        </RecommendationDetails>
        {inlineButton && (
          <ButtonContainer>
            <Button
              variant="primary"
              shape="square"
              disabled={primaryDisabled}
              onClick={handleButtonClick}
              suffixIcon={!primaryDisabled && ArrowRight}
            >
              {primaryText}
            </Button>
          </ButtonContainer>
        )}
      </Row>
    </Container>
  )
}

RecommendationCardBase.propTypes = {
  categoryId: propTypeFromEnumKeys(Categories).isRequired,
  important: PropTypes.bool,
  isNew: PropTypes.bool,
  inlineButton: PropTypes.bool,
  onButtonClick: PropTypes.func,
  onCardClick: PropTypes.func,
  primaryDisabled: PropTypes.bool,
  primaryText: PropTypes.string,
}

export const RecommendationCard = ({
  categoryId,
  locationId,
  recommendationId,
  primaryDisabled,
  primaryText,
  important,
  isNew,
}) => {
  const { isMobile } = useIsMobile(Breakpoint.sm)

  const goToInsurance = useGoToPath(
    `${paths.viewInsuranceWithKey({
      categoryId,
      locationId,
    })}${makeQueryString({ recommendationId })}`,
  )

  const goToRecommendation = useGoToPath(
    paths.viewRecommendationWithKey(recommendationId),
  )

  return (
    <RecommendationCardBase
      categoryId={categoryId}
      important={important}
      inlineButton={!isMobile}
      onButtonClick={goToRecommendation}
      onCardClick={goToInsurance}
      primaryDisabled={primaryDisabled}
      primaryText={primaryText}
      isNew={isNew}
    />
  )
}

RecommendationCard.propTypes = {
  categoryId: propTypeFromEnumKeys(Categories).isRequired,
  important: PropTypes.bool,
  isNew: PropTypes.bool,
  locationId: PropTypes.string,
  primaryDisabled: PropTypes.bool,
  primaryText: PropTypes.string,
  recommendationId: PropTypes.string,
}
