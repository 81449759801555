import React from 'react'
import styled from 'styled-components'
import { withSvgProps } from './helpers'
import { ReactComponent as SvgComponent } from './svg/pulse.svg'

type SvgProps = React.ComponentProps<typeof SvgComponent>

const NoFillSvg = styled(SvgComponent)`
  fill: none;
`

const Svg = React.forwardRef<SVGSVGElement, SvgProps>((props, ref) => (
  <NoFillSvg {...props} ref={ref} />
))
Svg.displayName = 'Pulse'

export const PulseIcon = withSvgProps(Svg as React.FunctionComponent<SvgProps>)
