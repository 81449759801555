import { FormAddressAutocomplete } from '@/components'
import { AssessmentStepKeys } from '@/enums'
import {
  FormContent,
  StepContainer,
  StepFooter,
  StepHeader,
} from '@/modules/yearlyCheck/components/steps/components'
import { useStepper } from '@/modules/yearlyCheck/hooks/useStepper'
import { useSaveAssessmentStep } from '@/pages/SelfAssessmentPage/helpers'
import { FormProvider, useFormContext } from '@/providers'
import { useTranslation } from 'react-i18next'
import { makeBusinessDetailsValidationSchema } from '../../helpers/validationSchema'
import { useSegment } from '@/modules/analytics'

const BusinessDetailsForm = () => {
  const { t } = useTranslation()

  const { values } = useFormContext()
  const { onNext } = useStepper()
  const analytics = useSegment()

  const modifier = (name) => name
  const { save, isLoading: isButtonLoading } = useSaveAssessmentStep(
    AssessmentStepKeys.locationDetails,
    (value) => ({
      ...value,
      name: modifier(value.name),
    }),
    true,
  )

  const handleBusinessDetailsChange = () => {
    if (!values || !values.address) return

    save().then(async () => {
      await analytics.track('user_app_yearly_check_business_details')
      onNext(AssessmentStepKeys.locationDetails, values)
    })
  }

  return (
    <>
      <StepHeader
        sectionLabel={t('yearlyAssessment.businessDetails-category')}
        title={t('yearlyAssessment.businessDetails-title')}
      />
      <FormContent>
        <FormAddressAutocomplete />
      </FormContent>
      <StepFooter
        isButtonLoading={isButtonLoading}
        onSubmit={handleBusinessDetailsChange}
      />
    </>
  )
}

export const BusinessDetails = () => {
  const { formValues } = useStepper()
  const { t } = useTranslation()

  return (
    <StepContainer>
      <FormProvider
        initialValues={{
          name: formValues.location_details?.name,
          address: formValues.location_details?.address,
        }}
        validationSchema={() =>
          makeBusinessDetailsValidationSchema(
            t('validations', { returnObjects: true }),
          )
        }
      >
        <BusinessDetailsForm />
      </FormProvider>
    </StepContainer>
  )
}
