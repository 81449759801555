import { ThemeColor } from '@/enums'
import { withSvgProps } from './helpers'
import { ReactComponent as SvgComponent } from './svg/check-circle.svg'

type SvgProps = React.ComponentProps<typeof SvgComponent>

const Svg: React.FC<SvgProps> = (props) => <SvgComponent {...props} />

export const Icon = withSvgProps(Svg)

export const CheckCircle: React.FC<React.ComponentProps<typeof Icon>> = (
  props,
) => <Icon {...props} color={ThemeColor.b0} size={50} stroke={false} />
