import {
  BackComponent,
  ContactExpert,
  FormInput,
  FormPhone,
  PageMainContent,
  PageWidgetContainer,
  Typography,
} from '@/components'
import { hasCompaniesPoASigned, isUserMD } from '@/pages/Profile/helpers'
import { FormProvider, useSnackbar } from '@/providers'
import { useUpdateUser } from '@/services'
import { UserProfile } from '@/types/user-profile'
import { Button } from '@surein/ui'
import { useTranslation } from 'react-i18next'
import { useOutletContext } from 'react-router-dom'
import { PageTitle, ProfileButtonsWrapper } from '../styles'

interface OutletContext {
  user: UserProfile
}

export const ProfileData = () => {
  const { user } = useOutletContext<OutletContext>()
  const snackbar = useSnackbar()
  const { t } = useTranslation()
  const createUpdateUserMutation = useUpdateUser(user?.auth0 ?? '')

  const handleSubmit = (values: Partial<UserProfile>) => {
    createUpdateUserMutation
      .mutateAsync(values)
      .then(() => {
        snackbar.success(t('snackbars.success.updateUserMsg'))
      })
      .catch(() => snackbar.error(t('snackbars.error.generalMsg')))
  }

  const userIsMD = isUserMD(user) && hasCompaniesPoASigned(user)

  return (
    <>
      <PageMainContent>
        <BackComponent />
        <PageTitle>
          <Typography variant="h4">{t('profile.titles.profile')}</Typography>
        </PageTitle>
        <FormProvider initialValues={user} onSubmit={handleSubmit}>
          <FormInput
            disabled={userIsMD}
            label={t('profile.data.name.label')}
            name="name"
          />
          <FormInput
            disabled={userIsMD}
            label={t('profile.data.surname.label')}
            name="surname"
          />
          <FormInput
            disabled
            label={t('profile.data.email.label')}
            name="email"
          />
          <FormPhone label={t('profile.data.phone.label')} name="phone" />
          <ProfileButtonsWrapper>
            <Button variant="primary" shape="square" type="submit">
              {t('save_changes')}
            </Button>
          </ProfileButtonsWrapper>
        </FormProvider>
      </PageMainContent>
      <PageWidgetContainer>
        <ContactExpert
          text={t('personal_details_advisor_text')}
          reverted={false}
          buttonComponent={null}
          noMobilePadding={false}
          buttonText=""
        />
      </PageWidgetContainer>
    </>
  )
}
