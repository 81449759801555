import { WithWrapper } from '@/components/hoc/WithWrapper'
import { noop } from '@/utils'
import { Form, Formik, useField, useFormikContext } from 'formik'
import { __, isEmpty, pick, prop } from 'ramda'
import { useEffect, useRef, useState } from 'react'

const FormChangeListener = ({
  onChange = noop,
  children,
}: {
  // TODO: fix any type
  onChange: (values: any) => void
  children: React.ReactNode
}) => {
  const { values } = useFormikContext()
  const mounted = useRef<boolean>()

  useEffect(
    () => () => {
      mounted.current = false
    },
    [],
  )

  useEffect(() => {
    if (mounted.current) {
      onChange(values)
    } else {
      mounted.current = true
    }
  }, [values])

  return children
}

export const FormProvider = ({
  children,
  initialValues = {},
  onSubmit,
  onChange,
  ...props
}: {
  children: React.ReactNode
  // TODO: fix any type
  initialValues?: any
  onSubmit: (values: any) => void
  onChange?: (values: any) => void
  validationSchema?: any
}) => (
  <Formik
    initialValues={initialValues}
    onSubmit={onSubmit}
    validateOnChange={false}
    {...props}
  >
    <WithWrapper
      onChange={onChange}
      wrapper={FormChangeListener}
      wrapWhen={onChange}
    >
      <Form>{children}</Form>
    </WithWrapper>
  </Formik>
)

export const useFormItem = (name: string) => {
  const [, { value, error }, { setValue }] = useField({ name })

  return { value, onChange: setValue, error }
}

function isValidationState(
  state: unknown,
): state is {
  fields: string[]
  onSuccess: (values: any) => void
  onFail: () => void
} {
  return (
    typeof state === 'object' &&
    state !== null &&
    'fields' in state &&
    'onSuccess' in state &&
    'onFail' in state
  )
}

export const useFormContext = <TValues,>() => {
  const formikContext = useFormikContext<TValues>()
  const [validationState, setValidationState] = useState<{
    fields: (keyof TValues)[]
    onSuccess: (values: any) => void
    onFail: () => void
  } | null>(null)

  useEffect(() => {
    if (isValidationState(validationState)) {
      const { fields, onSuccess, onFail } = validationState

      if (isEmpty(pick(fields, formikContext.errors))) {
        onSuccess(pick(fields, formikContext.values))
      } else {
        onFail()
      }

      setValidationState(null)
    }
  }, [formikContext.errors, validationState])

  const scrollToError = ({ errors = {}, forceScroll = false }) => {
    if (isEmpty(errors) && !forceScroll) return
    const selector = '.input-error-styles'
    const el = document.querySelector(selector)
    el?.scrollIntoView()
  }

  const customValidate = async ({
    fields,
    onSuccess,
    onFail = noop,
  }: {
    fields?: (keyof TValues)[]
    onSuccess: () => void
    onFail?: () => void
  }) => {
    if (!fields || isEmpty(fields)) {
      formikContext.validateForm().then((errors) => {
        if (isEmpty(errors)) {
          onSuccess()
        } else {
          scrollToError({ errors })
          onFail()
        }
      })
    } else {
      await Promise.all(
        fields.map((val) => formikContext.validateField(val as string)),
      )
      scrollToError({ errors: formikContext.errors, forceScroll: true })
      setValidationState({ fields, onSuccess, onFail })
    }
  }
  const value = prop(__, formikContext.values)

  return { customValidate, value, ...formikContext }
}
