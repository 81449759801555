import {
  BackComponent,
  ButtonLink,
  Column,
  CompanyCard,
  CompanyCardHeader,
  CompanyCardItem,
  ContactExpert,
  PageMainContent,
  PageWidgetContainer,
  Row,
  TextWithLink,
  Typography,
} from '@/components'
import { ComponentColors, ThemeColor } from '@/enums'
import { paths } from '@/routes/paths'
import { Address } from '@/types/address'
import { makeAddress, mapWithKey, useGoToPath } from '@/utils'
import { Trans, useTranslation } from 'react-i18next'
import { useOutletContext } from 'react-router-dom'
import { getCanEditCompany } from '../helpers'
import { ButtonContainer, ItemContainer, PageTitle } from '../styles'
import { LinkWithPermissions } from './LinkWithPermissions'
import { UserProfileCompany } from '@/types/user-profile'

interface Location {
  locationId: string
  name: string
  address: Address
}

interface Company {
  companyId: string
  name: string
  locations: Location[]
}

interface User {
  companies: UserProfileCompany[]
}

interface OutletContext {
  user: User
  companies: Company[]
  hasProtectedAccess: boolean
}

interface LocationListItemProps {
  locationId: string
  name: string
  address: Address
}

const LocationListItem = ({
  locationId,
  name,
  address,
}: LocationListItemProps) => {
  const { t } = useTranslation()
  const goToEditLocation = useGoToPath(paths.editLocationWithKey(locationId))

  return (
    <CompanyCardItem>
      <ItemContainer>
        <Column gap="4px">
          <Typography variant="p2Body">{name}</Typography>
          <Typography color={ThemeColor.b50} variant="badgeText">
            {makeAddress(address)}
          </Typography>
        </Column>
        <ButtonContainer>
          <ButtonLink
            color={ComponentColors.primary}
            onClick={goToEditLocation}
            size="small"
          >
            {t('common.edit')}
          </ButtonLink>
        </ButtonContainer>
      </ItemContainer>
    </CompanyCardItem>
  )
}

interface ProfileCompanyCardProps {
  companyId: string
  name: string
  locations: Location[]
}

const ProfileCompanyCard = ({
  locations,
  companyId: id,
  name,
}: ProfileCompanyCardProps) => {
  const { t } = useTranslation()
  const goToNewLocation = useGoToPath(paths.addLocationWithKey(id))

  const { user } = useOutletContext<OutletContext>()
  const canEditCompany = getCanEditCompany(user.companies, id)

  return (
    <CompanyCard>
      <CompanyCardHeader>
        <Row alignItems="center" justifyContent="space-between" width="100%">
          <Typography variant="captionR">{name}</Typography>
          <LinkWithPermissions
            color={ComponentColors.primary}
            disabled={!canEditCompany}
            onClick={goToNewLocation}
            size="small"
          >
            {`+ ${t('add_location')}`}
          </LinkWithPermissions>
        </Row>
      </CompanyCardHeader>
      {mapWithKey(LocationListItem, 'locationId', locations)}
    </CompanyCard>
  )
}

export const ProfileCompanies = () => {
  const { t } = useTranslation()
  const { companies, hasProtectedAccess } = useOutletContext<OutletContext>()

  const goToAddCompany = useGoToPath(paths.addCompany)

  return (
    <>
      <PageMainContent>
        <BackComponent />
        <PageTitle>
          <Typography variant="h4">{t('profile.menu.companies')}</Typography>
        </PageTitle>
        <Column gap="16px">
          {mapWithKey(ProfileCompanyCard, 'companyId', companies || [])}
          <TextWithLink>
            <Trans
              components={[
                <LinkWithPermissions
                  color={ComponentColors.primary}
                  disabled={!hasProtectedAccess}
                  onClick={goToAddCompany}
                  size="small"
                >
                  {t('profile.menu.add_company_or_location_text')}
                </LinkWithPermissions>,
              ]}
              i18nKey="add_company_or_location_text"
            />
          </TextWithLink>
        </Column>
      </PageMainContent>
      <PageWidgetContainer>
        <ContactExpert text={t('profile.menu.company_advisor_text')} />
      </PageWidgetContainer>
    </>
  )
}
