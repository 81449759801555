import {
  CreatePaymentInfo,
  UpdatePaymentInfo,
  createPayments,
  deletePayments,
  getCompanyPayments,
  getPaymentsById,
  updatePayments,
} from '@/api'
import { useInvalidateOnSuccessMutation } from '@/utils'
import { useQuery } from 'react-query'

const keys = {
  payments: 'payments',
  payment: 'payment',
}

export const usePayment = (id: string, options?: any) =>
  useQuery([keys.payment, id], () => getPaymentsById(id), options)

export const useCompanyPayments = (id: string, options?: any) =>
  useQuery([keys.payments, id], () => getCompanyPayments(id), options)

export const useDeletePayments = () =>
  useInvalidateOnSuccessMutation(keys.payments, (id) => deletePayments(id))

export const useCreatePayment = () =>
  useInvalidateOnSuccessMutation(
    keys.payments,
    ({ name, iban, bic, priority, companyId }: CreatePaymentInfo) =>
      createPayments({ name, iban, bic, priority, companyId }),
  )

export const useUpdatePayment = (id: string) =>
  useInvalidateOnSuccessMutation(
    [keys.payments, [keys.payment, id]],
    ({ name, iban, bic, priority }: UpdatePaymentInfo) =>
      updatePayments(id, { name, iban, bic, priority }),
  )
