import { ButtonLink, Tooltip, Typography } from '@/components'
import { ThemeColor } from '@/enums'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { ButtonLinkWithPermissionsWrapper } from '../styles'

interface LinkWithPermissionsProps
  extends React.ComponentProps<typeof ButtonLink> {
  children: React.ReactNode
  disabled?: boolean
}

export const LinkWithPermissions = ({
  children,
  disabled,
  ...props
}: LinkWithPermissionsProps) => {
  const { t } = useTranslation()

  return disabled ? (
    <ButtonLinkWithPermissionsWrapper>
      <Tooltip content={t('restrictedAccess')} dark>
        <Typography color={ThemeColor.b50} variant="p1Body">
          {children}
        </Typography>
      </Tooltip>
    </ButtonLinkWithPermissionsWrapper>
  ) : (
    <ButtonLink {...props}>{children}</ButtonLink>
  )
}

LinkWithPermissions.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
}
