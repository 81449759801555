import { isImgTypeSupported } from '@/utils'
import { useEffect, useState } from 'react'
import { config } from '../../../config'

interface ImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  name: string
  type?: 'svg' | 'png' | 'jpg' | 'jpeg' | 'gif' | 'webp'
  fallbackType?: 'svg' | 'png' | 'jpg' | 'jpeg' | 'gif' | 'webp'
}

export const Image = ({
  name,
  type = 'svg',
  fallbackType,
  ...props
}: ImageProps) => {
  const [ext, setExt] = useState(type)

  useEffect(() => {
    isImgTypeSupported(type).then((supported) => {
      if (!supported && fallbackType) setExt(fallbackType)
    })
  }, [])

  return <img alt={`${name} icon`} src={`/assets/${name}.${ext}`} {...props} />
}

export type ImageType = 'svg' | 'png' | 'jpg' | 'jpeg' | 'gif' | 'webp'

interface ImageFromCdnProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  name: string
  type?: ImageType
  fallbackType?: ImageType
}

export const ImageFromCdn = ({
  name,
  type = 'png',
  fallbackType,
  ...props
}: ImageFromCdnProps) => {
  const [ext, setExt] = useState(type)

  useEffect(() => {
    isImgTypeSupported(type).then((supported) => {
      if (!supported && fallbackType) setExt(fallbackType)
    })
  }, [])

  return (
    <img alt={`${name} img`} src={`${config.cdn}/${name}.${ext}`} {...props} />
  )
}

interface UseImageUrlFromCdnProps
  extends React.ImgHTMLAttributes<HTMLImageElement> {
  type?: ImageType
  fallbackType?: ImageType
}

export const useImageUrlFromCdn = ({
  type = 'png',
  fallbackType,
}: UseImageUrlFromCdnProps) => {
  const [ext, setExt] = useState<ImageType>(type)

  useEffect(() => {
    isImgTypeSupported(type).then((supported) => {
      if (!supported && fallbackType) setExt(fallbackType)
    })
  }, [])

  const imageUrlFromCdn = (name: string) => `${config.cdn}/${name}.${ext}`

  return { imageUrlFromCdn }
}
