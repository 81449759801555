import { Column } from '@/components'
import { Skeleton } from '@surein/ui'

export const SkeletonLoader = () => {
  return (
    <Column gap="16px">
      <Column gap="8px">
        <Skeleton id="skeleton-loader" count={1} height="32px" width="80%" />
        <Skeleton id="skeleton-loader" count={1} width="60%" />
      </Column>
      <Column gap="8px">
        <Skeleton id="skeleton-loader" count={12} height="24px" />
      </Column>
      <Column gap="8px">
        <Skeleton id="skeleton-loader" count={1} height="32px" width="80%" />
        <Skeleton id="skeleton-loader" count={1} width="60%" />
      </Column>
      <Column gap="8px">
        <Skeleton id="skeleton-loader" count={12} height="24px" />
      </Column>
    </Column>
  )
}
