import { Column, Typography } from '@/components'
import { Categories, ThemeColor } from '@/enums'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const HeaderContainer = styled(Column)`
  gap: 8px;
  padding: 16px 24px;
`

interface HeaderProps {
  categoryId: string | null
  title: string
  subtitle?: string
}

export const Header = ({ categoryId, title, subtitle }: HeaderProps) => {
  const { t } = useTranslation()
  const categoryKey = Categories[categoryId as keyof typeof Categories]?.key

  return (
    <HeaderContainer>
      {categoryKey && (
        <Typography color={ThemeColor.glass500} variant="body">
          {t(`categories.${categoryKey}.name`)}
        </Typography>
      )}
      <Typography bold variant="h4">
        {title}
      </Typography>
      {subtitle && (
        <Typography color={ThemeColor.glass500} variant="description">
          {subtitle}
        </Typography>
      )}
    </HeaderContainer>
  )
}
