import { AssessmentStepKeys } from '@/enums'
import {
  ApproxLaborCostStep,
  ApproxLaborCostStepTestimonial,
} from './ApproxLaborCostStep'
import {
  ApproxRevenueStep,
  ApproxRevenueStepTestimonial,
} from './ApproxRevenueStep'
import { AssessmentLoadingStep } from './AssessmentLoadingStep'
import {
  BusinessTypeStep,
  BusinessTypeStepTestimonial,
} from './BusinessTypeStep'
import { CompanyDetailsStep } from './CompanyDetailsStep'
import {
  CompanyFoundedDateLeadStep,
  CompanyFoundedDateLeadTestimonial,
} from './CompanyFoundedDateLeadStep'
import { CompanyFoundedDateStep } from './CompanyFoundedDateStep'
import { ConstructionAreaStep } from './ConstructionAreaStep'
import { ConstructionDateStep } from './ConstructionDateStep'
import { ConstructionValueStep } from './ConstructionValueStep'
import { ContactDataStep } from './ContactDataStep'
import { CreateAccountStep } from './CreateAccountStep'
import { CreateAccountStepB } from './CreateAccountStepB'
import { CreateLeadImage, CreateLeadStep } from './CreateLeadStep'
import { CurrentInsurancesStep } from './CurrentInsurancesStep'
import {
  EmployeesCountStep,
  EmployeesCountStepTestimonial,
} from './EmployeesCountStep'
import { HowCanWeHelpLeadStep } from './HowCanWeHelpLeadStep'
import { HowCanWeHelpStep } from './HowCanWeHelpStep'
import { InventoryStep } from './InventoryStep'
import { LastYearFinancesStep } from './LastYearFinancesStep'
import { LocationActivitiesStep } from './LocationActivitiesStep'
import { LocationDetailsStep } from './LocationDetailsStep'
import { LocationOpenSeasonStep } from './LocationOpenSeasonStep'
import { LocationRentStep } from './LocationRentStep'
import { LocationCountStep } from './LocationsCountStep'
import { PowerOfAttorneyStep } from './PowerOfAttorneyStep'
import {
  PrepareOfferStepB,
  PrepareOfferStepBTestimonial,
} from './PrepareOfferStepB'
import { StartDateStep } from './StartDateStep'
import { StepperTestimonial } from './StepperTestimonial'
import { UserProfileStep } from './UserProfileStep'
import { BeforeYouStartStep } from './BeforeYouStartStep'
import { ContactDataImage } from './components/ContactDataStepA'

export const AssessmentSteps = {
  [AssessmentStepKeys.howCanWeHelp]: {
    component: HowCanWeHelpStep,
    img: StepperTestimonial,
  },
  [AssessmentStepKeys.howCanWeHelpLead]: {
    component: HowCanWeHelpLeadStep,
    img: StepperTestimonial,
  },
  [AssessmentStepKeys.userProfile]: {
    component: UserProfileStep,
    img: StepperTestimonial,
  },
  [AssessmentStepKeys.businessType]: {
    component: BusinessTypeStep,
    img: BusinessTypeStepTestimonial,
  },
  [AssessmentStepKeys.companyDetails]: {
    component: CompanyDetailsStep,
    img: StepperTestimonial,
  },
  [AssessmentStepKeys.companyFoundedDate]: {
    component: CompanyFoundedDateStep,
    img: StepperTestimonial,
  },
  [AssessmentStepKeys.companyFoundedDateLead]: {
    component: CompanyFoundedDateLeadStep,
    img: CompanyFoundedDateLeadTestimonial,
  },
  [AssessmentStepKeys.contactData]: {
    component: ContactDataStep,
    img: ContactDataImage,
  },
  [AssessmentStepKeys.locationsCount]: {
    component: LocationCountStep,
    img: StepperTestimonial,
  },
  [AssessmentStepKeys.locationDetails]: {
    component: LocationDetailsStep,
    img: StepperTestimonial,
  },
  [AssessmentStepKeys.locationOpenSeason]: {
    component: LocationOpenSeasonStep,
    img: StepperTestimonial,
  },
  [AssessmentStepKeys.employeesCount]: {
    component: EmployeesCountStep,
    img: EmployeesCountStepTestimonial,
  },
  [AssessmentStepKeys.lastYearFinances]: {
    component: LastYearFinancesStep,
    img: StepperTestimonial,
  },
  [AssessmentStepKeys.approxRevenue]: {
    component: ApproxRevenueStep,
    img: ApproxRevenueStepTestimonial,
  },
  [AssessmentStepKeys.approxLaborCosts]: {
    component: ApproxLaborCostStep,
    img: ApproxLaborCostStepTestimonial,
  },
  [AssessmentStepKeys.locationActivities]: {
    component: LocationActivitiesStep,
    img: StepperTestimonial,
  },
  [AssessmentStepKeys.locationRent]: {
    component: LocationRentStep,
    img: StepperTestimonial,
  },
  [AssessmentStepKeys.currentInsurances]: {
    component: CurrentInsurancesStep,
    img: StepperTestimonial,
  },
  [AssessmentStepKeys.powerOfAttorney]: {
    component: PowerOfAttorneyStep,
    img: StepperTestimonial,
  },
  [AssessmentStepKeys.inventory]: {
    component: InventoryStep,
    img: StepperTestimonial,
  },
  [AssessmentStepKeys.startDate]: {
    component: StartDateStep,
    img: StepperTestimonial,
  },
  [AssessmentStepKeys.prepareData]: {
    component: AssessmentLoadingStep,
    img: StepperTestimonial,
  },
  [AssessmentStepKeys.prepareOfferB]: {
    component: PrepareOfferStepB,
    img: PrepareOfferStepBTestimonial,
  },
  [AssessmentStepKeys.createAccount]: {
    component: CreateAccountStep,
    img: StepperTestimonial,
  },
  [AssessmentStepKeys.createAccountB]: {
    component: CreateAccountStepB,
    img: StepperTestimonial,
  },
  [AssessmentStepKeys.createLead]: {
    component: CreateLeadStep,
    img: CreateLeadImage,
  },
  [AssessmentStepKeys.constructionDetails]: {
    component: ConstructionValueStep,
    img: StepperTestimonial,
  },
  [AssessmentStepKeys.constructionDate]: {
    component: ConstructionDateStep,
    img: StepperTestimonial,
  },
  [AssessmentStepKeys.constructionArea]: {
    component: ConstructionAreaStep,
    img: StepperTestimonial,
  },
  [AssessmentStepKeys.beforeYouStart]: {
    component: BeforeYouStartStep,
    img: StepperTestimonial,
  },
}
