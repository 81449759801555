// config file
import { isProd } from './utils/environment'

// TODO: Decommission these SSF domains and LPs config, keep only relevant ones
const domains = {
  liabilitySsfDomain: 'angebot-gewerbe.surein.de',
  legalSsfDomain: 'angebot-rechtsschutzversicherung.surein.de',
  contentSsfDomain: 'angebot-inhaltsversicherung.surein.de',
  cyberSsfDomain: 'angebot-cyber.surein.de',
  liabilityLeadDomain: 'gewerbeversicherung.surein.de',
  cyberLeadDomain: 'cyber.surein.de',
  financialLossLeadDomain: 'vermoegensschadenhaftpflicht.surein.de',
  professionalLiabilityLeadDomain: 'berufshaftpflicht.surein.de',
  buildingLeadDomain: 'gebaeudeversicherung.surein.de',
  contentLeadDomain: 'inhaltsversicherung.surein.de',
  legalLeadDomain: 'firmenrechtsschutzversicherung.surein.de',
  gastronomyLeadDomain: 'gastronomie.surein.de',
  appDomain: 'app.surein.de',
  partnerDomain: 'partner.surein.de',
}

const gtmKeys = {
  [domains.appDomain]: import.meta.env.REACT_APP_GTM_ID,
  [domains.liabilitySsfDomain]: import.meta.env.REACT_APP_GTM_SSF_LI,
  [domains.contentSsfDomain]: import.meta.env.REACT_APP_GTM_SSF_LI,
  [domains.legalSsfDomain]: import.meta.env.REACT_APP_GTM_SSF_LI,
  [domains.cyberSsfDomain]: import.meta.env.REACT_APP_GTM_SSF_LI,
  [domains.liabilityLeadDomain]: import.meta.env.REACT_APP_GTM_LEAD,
  [domains.cyberLeadDomain]: import.meta.env.REACT_APP_GTM_LEAD,
  [domains.financialLossLeadDomain]: import.meta.env.REACT_APP_GTM_LEAD,
  [domains.professionalLiabilityLeadDomain]: import.meta.env.REACT_APP_GTM_LEAD,
  [domains.buildingLeadDomain]: import.meta.env.REACT_APP_GTM_LEAD,
  [domains.contentLeadDomain]: import.meta.env.REACT_APP_GTM_LEAD,
  [domains.legalLeadDomain]: import.meta.env.REACT_APP_GTM_LEAD,
  [domains.gastronomyLeadDomain]: import.meta.env.REACT_APP_GTM_LEAD,
  [domains.partnerDomain]: import.meta.env.REACT_APP_GTM_ID,
}

const mixpanelKeys = {
  [domains.appDomain]: import.meta.env.REACT_APP_MIXPANEL_ID,
  [domains.liabilitySsfDomain]: import.meta.env.REACT_APP_MIXPANEL_ID_SSF,
  [domains.legalSsfDomain]: import.meta.env.REACT_APP_MIXPANEL_ID_SSF,
  [domains.cyberSsfDomain]: import.meta.env.REACT_APP_MIXPANEL_ID_SSF,
  [domains.contentSsfDomain]: import.meta.env.REACT_APP_MIXPANEL_ID_SSF,
  [domains.liabilityLeadDomain]: import.meta.env.REACT_APP_MIXPANEL_ID_LEAD,
  [domains.cyberLeadDomain]: import.meta.env.REACT_APP_MIXPANEL_ID_LEAD,
  [domains.financialLossLeadDomain]: import.meta.env.REACT_APP_MIXPANEL_ID_LEAD,
  [domains.professionalLiabilityLeadDomain]: import.meta.env
    .REACT_APP_MIXPANEL_ID_LEAD,
  [domains.buildingLeadDomain]: import.meta.env.REACT_APP_MIXPANEL_ID_LEAD,
  [domains.contentLeadDomain]: import.meta.env.REACT_APP_MIXPANEL_ID_LEAD,
  [domains.legalLeadDomain]: import.meta.env.REACT_APP_MIXPANEL_ID_LEAD,
  [domains.gastronomyLeadDomain]: import.meta.env.REACT_APP_GTM_LEAD,
}

const hotjarKeys = {
  [domains.appDomain]: import.meta.env.REACT_APP_HOTJAR_ID,
  [domains.partnerDomain]: import.meta.env.REACT_APP_HOTJAR_ID,
  [domains.liabilitySsfDomain]: import.meta.env.REACT_APP_HOTJAR_ID_LI_SSF,
  [domains.contentSsfDomain]: import.meta.env.REACT_APP_HOTJAR_ID_CO_SSF,
  [domains.legalSsfDomain]: import.meta.env.REACT_APP_HOTJAR_ID_LE_SSF,
  [domains.cyberSsfDomain]: import.meta.env.REACT_APP_HOTJAR_ID_CY_SSF,
}

const prodConfig = {
  hotjarSv:
    window.location.host === domains.appDomain &&
    import.meta.env.REACT_APP_HOTJAR_SV,
  segmentId: import.meta.env.REACT_APP_SEGMENT_KEY,
}

export const config = {
  ...domains,
  environment: import.meta.env.REACT_APP_ENV,
  appointmentUrl:
    'https://meetings-eu1.hubspot.com/maximilian-geissinger/terminbuchung-homepage',
  contactEmail: 'help@surein.de',
  contactPhone: '+49 30 837 989 96',
  midMarketPhone: '+49 30 311 996 33',
  midMarketCalendarUrl: 'https://calendly.com/danieldierkes/30-minute-meeting',
  referralUrl: 'http://get.surein.de/referral-1',
  reportDamageUrl: {
    en: 'https://share-eu1.hsforms.com/1zZ2gKWwVTMGuVn7o77hJAwfmzeu',
    de: 'https://share-eu1.hsforms.com/1zZ2gKWwVTMGuVn7o77hJAwfmzeu',
  },
  cdn: import.meta.env.REACT_APP_CDN,
  genericCarrierLogo: 'genericCarrier.png',
  gtmId: gtmKeys[window.location.host],
  gtmAuth: import.meta.env.REACT_APP_GTM_AUTH,
  gtmPreview: import.meta.env.REACT_APP_GTM_PREVIEW,
  sentryDSN: import.meta.env.REACT_APP_SENTRY_DSN,
  sentryTracesSampleRate: import.meta.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE
    ? parseFloat(import.meta.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE)
    : 0,
  mixpanelKey: mixpanelKeys[window.location.host],
  backend: import.meta.env.REACT_APP_BACKEND,
  auth0ClientId: import.meta.env.REACT_APP_AUTH0_CLIENT_ID,
  authDomain: import.meta.env.REACT_APP_AUTH0_DOMAIN,
  adminApp: import.meta.env.REACT_APP_ADMIN,
  hotjarId: hotjarKeys[window.location.host],
  pidCookieKey: import.meta.env.REACT_APP_PID_COOKIE_KEY,
  midCookieKey: import.meta.env.REACT_APP_MID_COOKIE_KEY,
  backendApiAudience: import.meta.env.REACT_APP_BACKEND_API_AUDIENCE,
  googlePlacesApiKey: import.meta.env.REACT_APP_GOOGLE_PLACES_API_KEY,
  customerPortalUrl: import.meta.env.REACT_APP_CUSTOMER_PORTAL_URL,
  landingPage: 'https://surein.de/',
  segmentId: import.meta.env.REACT_APP_SEGMENT_KEY,
  failedCalculationLinkEn: 'https://surein.de/failed-calculation',
  failedCalculationLinkDe: 'https://surein.de/failed-calculation-en',
  partnerDomain: import.meta.env.REACT_APP_PARTNER_DOMAIN,
  qontoAppUrl: import.meta.env.REACT_APP_QONTO_APP_URL,
  qontoInsuranceHubUrl: import.meta.env.REACT_APP_QONTO_INSURANCE_HUB_URL,
  appUrl: import.meta.env.REACT_APP_APP_URL,
  ...(isProd() ? prodConfig : {}),
}
