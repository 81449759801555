export const ThemeColor = {
  info1: 'info1',
  info2: 'info2',
  info3: 'info3',
  info4: 'info4',
  success1: 'success1',
  success2: 'success2',
  success3: 'success3',
  success4: 'success4',
  warning1: 'warning1',
  warning2: 'warning2',
  warning3: 'warning3',
  warning4: 'warning4',
  danger1: 'danger1',
  danger2: 'danger2',
  danger3: 'danger3',
  danger4: 'danger4',
  b0: 'b0',
  b10: 'b10',
  b30: 'b30',
  b40: 'b40',
  b50: 'b50',
  b100: 'b100',
  electric100: 'electric100',
  electric200: 'electric200',
  electric300: 'electric300',
  electric400: 'electric400',
  electric500: 'electric500',
  electric900: 'electric900',
  glass0: 'glass0',
  glass100: 'glass100',
  glass200: 'glass200',
  glass300: 'glass300',
  glass500: 'glass500',
  glass900: 'glass900',
} as const

export const ThemeFontWeight = {
  light: 'light',
  regular: 'regular',
  medium: 'medium',
  semibold: 'semibold',
  bold: 'bold',
} as const

export const BreakpointType = {
  up: 'up',
  down: 'down',
  only: 'only',
  between: 'between',
} as const

export const Breakpoint = {
  xxs: 'xxs',
  xs: 'xs',
  sm: 'sm',
  md: 'md',
  tab: 'tab',
  lg: 'lg',
  xl: 'xl',
  '2xl': '2xl',
} as const

export const ComponentColors = {
  primary: 'primary',
  success: 'success',
  warning: 'warning',
  danger: 'danger',
  text: 'text',
} as const

export const TypographyVariants = {
  // Deprecated variants
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  captionB: 'captionB',
  captionR: 'captionR',
  p1Body: 'p1Body',
  p2Body: 'p2Body',
  button: 'button',
  placeholder: 'placeholder',
  inputDescription: 'inputDescription',
  inputLabel: 'inputLabel',
  badgeText: 'badgeText',
  // End deprecated variants
  pageTitle: 'pageTitle',
  sectionTitle: 'sectionTitle',
  itemTitle: 'itemTitle',
  subheadline: 'subheadline',
  body: 'body',
  description: 'description',
  footnote: 'footnote',
  micro: 'micro',
  inter: 'inter',
} as const
