import { AssessmentStepKeys } from '@/enums'
import {
  FormContent,
  StepContainer,
  StepFooter,
  StepHeader,
} from '@/modules/yearlyCheck/components/steps/components'
import { useStepper } from '@/modules/yearlyCheck/hooks/useStepper'
import { useSaveAssessmentStep } from '@/pages/SelfAssessmentPage/helpers'
import { BusinessTypeStep } from '@/pages/SelfAssessmentPage/steps/BusinessTypeStep'
import { FormProvider, useFormContext } from '@/providers'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { makeBusinessTypeValidationSchema } from '../../helpers/validationSchema'
import { useSegment } from '@/modules/analytics'

const OverflowContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
`

const BusinessTypeForm = () => {
  const { onNext } = useStepper()
  const { values } = useFormContext()
  const analytics = useSegment()
  const { t } = useTranslation()
  const { save, isLoading: isButtonLoading } = useSaveAssessmentStep(
    AssessmentStepKeys.businessType,
    undefined,
    true,
  )
  const handleBusinessTypeChange = () => {
    if (!values || values.length <= 0) return

    save().then(async () => {
      await analytics.track('user_app_yearly_check_business_type')
      onNext(AssessmentStepKeys.businessType, values)
    })
  }

  return (
    <>
      <OverflowContainer id="step-container">
        <StepHeader
          sectionLabel={t('stepperBreadcrumbs.businessType')}
          title={t('yearlyAssessment.businessType-title')}
        />
        <FormContent>
          <BusinessTypeStep />
        </FormContent>
      </OverflowContainer>
      <StepFooter
        isButtonLoading={isButtonLoading}
        onSubmit={handleBusinessTypeChange}
      />
    </>
  )
}

export const BusinessType = () => {
  const { formValues } = useStepper()
  const { t } = useTranslation()

  return (
    <StepContainer>
      <FormProvider
        initialValues={{
          businessType: formValues.business_type?.businessType,
          locationSubtypes: formValues.business_type?.locationSubtypes,
          locationType: formValues.business_type?.locationType,
          locationTypeSpecification:
            formValues.business_type?.locationTypeSpecification,
        }}
        validationSchema={() =>
          makeBusinessTypeValidationSchema(
            t('validations', { returnObjects: true }),
          )
        }
      >
        <BusinessTypeForm />
      </FormProvider>
    </StepContainer>
  )
}
