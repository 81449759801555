import { insuranceCardData } from '@/components/organisms/InsuranceFinderCard/helpers'
import { Categories, InsuranceLabels } from '@/enums'
import {
  getLocalStorageItem,
  propNotEq,
  safeDateString,
  sortDateBy,
} from '@/utils'
import {
  __,
  anyPass,
  both,
  complement,
  curry,
  evolve,
  filter,
  find,
  includes,
  keys,
  last,
  lt,
  pipe,
  pluck,
  prop,
  propEq,
  propOr,
  propSatisfies,
  reduce,
  reject,
  sortBy,
  uniq,
} from 'ramda'
import { checkCompletedAssessment } from '../EditLocationPage/helpers'
import { differenceInDays } from 'date-fns'

const sortByCategory = sortBy(
  pipe(prop('categoryId'), prop(__, Categories), prop('key')),
)

const checkIfProductExists = curry((key, data, categoryId) =>
  pipe(propOr([], key), pluck('categoryId'), includes(categoryId))(data),
)

export const checkIfRecommendationExists = checkIfProductExists(
  'recommendations',
)

const sortRecommendations = (essentialCategories) => (recommendations = []) => {
  const essentials = recommendations.filter(({ categoryId }) =>
    essentialCategories.includes(categoryId),
  )
  const others = reject(
    ({ categoryId }) => essentialCategories.includes(categoryId),
    recommendations,
  )

  return [...sortByCategory(essentials), ...sortByCategory(others)]
}

export const transform = (data) => {
  return evolve(
    {
      recommendations: sortRecommendations(data.essentialCategoryId),
    },
    data,
  )
}

const getExistingInsurances = pipe(
  prop('insurances'),
  filter(prop('insuranceId')),
)

const getAllPurchasedEssentialInsurances = (data) =>
  pipe(
    getExistingInsurances,
    filter(pipe(prop('purchased'), Boolean)),
    filter(pipe(prop('categoryId'), includes(__, data.essentialCategoryId))),
  )(data)

const getLastStartDate = pipe(
  getAllPurchasedEssentialInsurances,
  sortDateBy(prop('startDate')),
  pluck('startDate'),
  last,
  safeDateString,
)

const checkExcellent = both(
  propEq(100, 'riskScore'),
  complement(
    pipe(
      prop('insurances'),
      find(propNotEq('insuranceLabel', InsuranceLabels.veryGood)),
    ),
  ),
)

export const makeExpertContent = (data, messages) => {
  if (!checkCompletedAssessment(data)) {
    const { riskScore, essentialCategoryId } = data
    const essentialInsurancesCount = essentialCategoryId?.length

    const i18nKey =
      riskScore === 100
        ? messages.expertAllEssentialInsurances({
            hasNr: essentialInsurancesCount,
            totalNr: essentialInsurancesCount,
          })
        : messages.expertMissingEssentialInsurances({
            hasNr: Math.round((essentialInsurancesCount * riskScore) / 100),
            totalNr: essentialInsurancesCount,
          })

    return i18nKey
  }

  const recommendations = data?.recommendations || []

  if (recommendations.length) {
    return recommendations.length > 1
      ? messages.expertOfferReady({
          offerNr: recommendations.length,
        })
      : messages.expertOfferReadyOne({
          offerNr: recommendations.length,
        })
  }

  if (checkExcellent(data))
    return messages.expertOptimallyInsured({
      startDate: getLastStartDate(data),
    })

  return messages.expertOfferInProgress
}

const paymentPeriodMultipliers = {
  monthly: 12,
  quarterly: 4,
  'half-yearly': 2,
  yearly: 1,
  once: 1,
  'non-contributory': 0,
}

export const calcYearlyPrice = pipe(
  filter(both(prop('grossPrice'), prop('paymentPeriod'))),
  reduce(
    (acc, { grossPrice, paymentPeriod }) =>
      acc + grossPrice * paymentPeriodMultipliers[paymentPeriod],
    0,
  ),
)

export const calcOriginalYearlyPrice = pipe(
  filter(both(prop('grossPrice'), prop('paymentPeriod'))),
  reduce(
    (acc, { grossPrice, originalGrossPrice, paymentPeriod }) =>
      acc +
      (originalGrossPrice || grossPrice) *
        paymentPeriodMultipliers[paymentPeriod],
    0,
  ),
)

export const insuranceFinderSnoozeLocalStorageKey = 'insurance-finder-cards'

const checkIfSnoozed = (categoryId) =>
  propSatisfies(
    lt(new Date()),
    categoryId,
    getLocalStorageItem(insuranceFinderSnoozeLocalStorageKey) || {},
  )

export const makeCardData = (data) =>
  pipe(
    filter(prop('showInDashboard')),
    keys,
    reject(anyPass([checkIfSnoozed, checkIfProductExists('insurances', data)])),
  )(insuranceCardData)

export const findEssentialsWithoutRecommendations = (data) => {
  const essentials = data?.essentialCategoryId || []
  // Constant categories are categories that have to be shown regardless of essentials
  const constantCategories = ['CY', 'LE']
  const essentialsWithConstantCategories = uniq(
    essentials.concat(constantCategories),
  )
  const recommendations = data?.recommendations || []

  return essentialsWithConstantCategories.filter(
    (categoryId) =>
      !recommendations.some(propEq(categoryId, 'categoryId')) &&
      !getExistingInsurances(data).some(propEq(categoryId, 'categoryId')),
  )
}

export const isRecommendationNew = (createdAt) => {
  return differenceInDays(new Date(), new Date(createdAt)) < 2
}
