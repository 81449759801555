import { Breakpoint, ThemeColor } from '@/enums'
import { themeBreakpointDown, themeColor, toThemePixels } from '@/utils'
import styled from 'styled-components'
import { Column, Row } from '../../styled'

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
  background: linear-gradient(270deg, #F5F6FF -3.43%, #E6E8FF 101.06%);
  border-radius: ${toThemePixels(3)};
  padding: ${toThemePixels(4)};
  cursor: pointer;
  border: 1px solid ${themeColor(ThemeColor.b30)}};
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.02), 0px 2px 4px rgba(0, 0, 0, 0.08);

  h5 {
    margin: 0;
  }

  ${themeBreakpointDown(Breakpoint.sm)} {
    width: 100%;
    height: auto;
    background: linear-gradient(180deg, #F5F6FF 0%, #E6E8FF 100%);
  }
`

export const RecommendationDetails = styled(Column)`
  gap: ${toThemePixels(4)};
  width: 100%;
`

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;

  span {
    white-space: nowrap;
  }

  ${themeBreakpointDown(Breakpoint.sm)} {
    button {
      min-width: 180px;
    }
  }
`

export const Tag = styled(Row)`
  width: fit-content;
  padding: 8px 16px 8px 12px;
  border: 1px solid ${themeColor(ThemeColor.info3)};
  border-radius: ${toThemePixels(25)};
  color: ${themeColor(ThemeColor.b100)};
  background-color: ${themeColor(ThemeColor.b0)};
  align-items: center;
  gap: ${toThemePixels(2)};
`

export const NewTag = styled(Row)`
  width: fit-content;
  background-color: ${themeColor(ThemeColor.danger1)};
  border-radius: 200px;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;

  > span {
    font-size: 9px;
  }
`
