import {
  createCompany,
  createCompanyUser,
  deleteCompany,
  getCompanies,
  getCompanyById,
  getCompanyPoaStatus,
  getContractsOverview,
  requestEditCompany,
  signPoa,
  updateCompany,
} from '@/api'
import { useInvalidateOnSuccessMutation } from '@/utils'
import { useMutation, useQuery } from 'react-query'

const keys = {
  companies: 'companies',
  company: 'company',
  users: 'companyUsers',
  poa: 'poa',
}

export const useCompanies = (options: any) =>
  useQuery(keys.companies, getCompanies, options)

export const useCompany = (id: string, options?: any) =>
  useQuery([keys.company, id], () => getCompanyById(id), options)

export const useCreateCompany = () =>
  useInvalidateOnSuccessMutation(keys.companies, ({ company, location }) =>
    createCompany({ company, location }),
  )

export const useUpdateCompany = (id: string) =>
  useInvalidateOnSuccessMutation(
    [keys.companies, [keys.company, id], 'profile'],
    ({ name }) => updateCompany(id, { name }),
  )

export const useDeleteCompany = (id: string) =>
  useInvalidateOnSuccessMutation(keys.companies, () => deleteCompany(id))

export const useSignPoa = (companyId: string) =>
  useInvalidateOnSuccessMutation(
    [
      [keys.company, companyId],
      [keys.poa, companyId],
      'location1',
      keys.companies,
      'profile',
    ],
    ({ signature, birthdate }) => signPoa({ companyId, signature, birthdate }),
  )

export const useCreateCompanyUser = (id: string) =>
  useInvalidateOnSuccessMutation(
    [keys.company, id],
    ({ companyId, name, surname, email, phone, isMd }) =>
      createCompanyUser(id || companyId, { name, surname, email, phone, isMd }),
  )

export const useRequestEditCompany = (id: string) =>
  useMutation(() => requestEditCompany(id))

export const useCompanyPoaStatus = (id: string, options: any) =>
  useQuery([keys.poa, id], () => getCompanyPoaStatus(id), options)

export const useContractsOverview = (archivedView?: boolean) =>
  useQuery([keys.companies, 'insurances', archivedView], () =>
    getContractsOverview(archivedView),
  )
