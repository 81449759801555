import { createGlobalStyles, theme } from '@/theme'
import PropTypes from 'prop-types'
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components'

const GlobalStyles = createGlobalStyles()

interface ThemeProviderProps {
  children: React.ReactNode
}

export const ThemeProvider = ({ children }: ThemeProviderProps) => (
  <StyledComponentsThemeProvider theme={theme}>
    <GlobalStyles />
    {children}
  </StyledComponentsThemeProvider>
)

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
