export const formatRiskAddress = (address: {
  street: string
  // FIXME: why do we have two different fields for street number?
  streetNumber?: string
  streetNr?: string
  postalCode: string
  city: string
}) =>
  `${address.street} ${address.streetNumber || address.streetNr}, ${
    address.postalCode
  } ${address.city}`
