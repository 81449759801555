import { useTranslation } from 'react-i18next'
import { isNil } from 'ramda'
import { ddMMyyyy, formatMoney } from '@/utils'
import { formatRiskAddress } from '@/utils/address'

type Employees = {
  ownersCount: number | null
  fullTimeEmployeesCount: number | null
  partTimeEmployeesCount: number | null
  miniJobbersCount: number | null
}

const isAssessmentValid = (assessmentData: Record<string, unknown>) => {
  return Object.values(assessmentData).every(Boolean)
}

export const useRiskAdditionalInfo = (
  data:
    | {
        companyFoundedDate: { foundedDate: string } | null
        lastYearFinances: {
          approxTurnover: number | null
          approxPersonelExpenses: number | null
        } | null
        employeesCount: Employees | null
        inventorySum: number | null
        riskAddress: {
          street: string
          streetNumber: string
          postalCode: string
          city: string
        } | null
        startDate: {
          startDate: string
        }
      }
    | undefined,
  categoryKey: string,
): { additionalInfo: string | null } => {
  const { t } = useTranslation()

  const formatEmployees = (employees: Employees) => {
    const employeeTypes: { key: keyof Employees; label: string }[] = [
      { key: 'ownersCount', label: t('companyPage.usersRoles.owner') },
      { key: 'fullTimeEmployeesCount', label: t('full_time_employees') },
      { key: 'partTimeEmployeesCount', label: t('part_time_employees') },
      { key: 'miniJobbersCount', label: t('mini_jobbers') },
    ]

    return employeeTypes
      .map((type) => {
        if (!isNil(employees[type.key])) {
          return `${employees[type.key]}x ${t(type.label)}`
        }
        return null
      })
      .filter(Boolean)
      .join(', ')
  }

  // why this is nested fn? change to inline
  const parseRiskData = (): string | null => {
    if (
      !['liability', 'financialLoss', 'content', 'legal'].includes(categoryKey)
    )
      return null

    const foundedDate =
      data?.companyFoundedDate && ddMMyyyy(data.companyFoundedDate.foundedDate)
    const turnover =
      data?.lastYearFinances &&
      formatMoney(data.lastYearFinances.approxTurnover)
    const salaries =
      data?.lastYearFinances &&
      formatMoney(data.lastYearFinances.approxPersonelExpenses)
    const employees =
      data?.employeesCount && formatEmployees(data.employeesCount)
    const inventorySum = data?.inventorySum && formatMoney(data.inventorySum)
    const riskAddress = data?.riskAddress && formatRiskAddress(data.riskAddress)
    const startDate =
      data?.startDate?.startDate && ddMMyyyy(data?.startDate?.startDate)

    if (categoryKey === 'liability' || categoryKey === 'financialLoss') {
      const assessmentData = {
        turnover,
        foundedDate,
        employees,
        salaries,
      }

      return isAssessmentValid(assessmentData)
        ? t('recommendations.additionalAssessmentInfo', assessmentData)
        : null
    }

    if (categoryKey === 'content') {
      const assessmentData = {
        turnover,
        foundedDate,
        employees,
        inventorySum,
        riskAddress,
      }
      return isAssessmentValid(assessmentData)
        ? t('recommendations.additionalAssessmentInfoContent', assessmentData)
        : null
    }

    if (categoryKey === 'legal') {
      const assessmentData = {
        turnover,
        foundedDate,
        employees,
        ...(startDate && { startDate }),
      }

      if (startDate) {
        return isAssessmentValid(assessmentData)
          ? t('recommendations.additionalAssessmentInfoLegal', assessmentData)
          : null
      } else {
        return isAssessmentValid(assessmentData)
          ? t(
              'recommendations.additionalAssessmentInfoLegalNoStartDate',
              assessmentData,
            )
          : null
      }
    }

    return null
  }

  return {
    additionalInfo: parseRiskData(),
  }
}
