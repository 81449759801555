import { PlusMinus } from '@/components/atoms'
import { ThemeColor } from '@/enums'
import { useFormContext, useFormItem } from '@/providers'
import { formatNumberValue } from '@/utils'
import { NumberInput } from '@surein/ui'
import { LucideIcon } from 'lucide-react'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { getError } from './helpers'

const StyledMessage = styled.p<{ color: keyof typeof ThemeColor }>`
  cursor: inherit;
  white-space: pre-line;
  line-height: 140%;
  font-weight: 700;
  font-size: 12px;
  color: ${(props) => props.theme.color[props.color || 'b50']};
`

interface FormNumberInputProps {
  name: string
  label: string
  disabled?: boolean
  prefixIcon?: LucideIcon
  suffixIcon?: LucideIcon
  infoMessage?: string
  showErrorMessage?: boolean
  showValidCheck?: boolean
  validateOnChange?: boolean
  addon?: React.ReactNode
  min?: number
  max?: number
  withButtons?: boolean
  initialValue?: number
}

export const FormNumberInput = ({
  name,
  label,
  disabled,
  prefixIcon,
  suffixIcon,
  infoMessage,
  showErrorMessage = true,
  showValidCheck = false,
  validateOnChange = false,
  addon,
  withButtons,
  min,
  max,
  ...props
}: FormNumberInputProps) => {
  const { validateField } = useFormContext()
  const [touched, setIsTouched] = useState(false)
  const { value, onChange, error } = useFormItem(name)
  const errorMsg = getError(error) as string | undefined

  useEffect(() => {
    if (touched && validateOnChange && value) validateField(name)
  }, [validateOnChange, touched, value])

  const hasErrorMessage = errorMsg && showErrorMessage
  const isSuccessful = showValidCheck && touched && !error && value

  const handleBlur = () => {
    if (touched) validateField(name)
  }

  return (
    <div className="flex flex-col gap-2">
      <NumberInput
        disabled={disabled}
        id={name}
        isErrored={Boolean(error)}
        isSuccessful={isSuccessful}
        label={label}
        name={name}
        addon={
          withButtons ? (
            <PlusMinus onChange={onChange} value={value} min={min} max={max} />
          ) : (
            addon
          )
        }
        onBlur={handleBlur}
        onChange={onChange}
        onFocus={() => setIsTouched(true)}
        prefixIcon={prefixIcon}
        suffixIcon={suffixIcon}
        // @ts-expect-error formatNumberValue is not typed
        value={formatNumberValue(value)}
        {...props}
      />

      {hasErrorMessage && (
        <StyledMessage color={ThemeColor.danger2}>{errorMsg}</StyledMessage>
      )}

      {infoMessage && !hasErrorMessage && (
        <StyledMessage color={ThemeColor.b50}>{infoMessage}</StyledMessage>
      )}
    </div>
  )
}
