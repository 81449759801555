import { Column, Row, Typography } from '@/components'
import { Breakpoint, ThemeColor } from '@/enums'
import { themeBreakpointDown, themeColor, toThemePixels } from '@/utils'
import styled from 'styled-components'

export const StepContainer = styled(Column)`
  height: 100%;
`

export const StepTitle = styled(Typography).attrs({
  variant: 'h4',
})`
  margin-bottom: ${toThemePixels(6)};
`

export const Divider = styled.div`
  width: 100%;
  border-bottom: 1px solid ${themeColor(ThemeColor.b30)};
  margin: ${toThemePixels(1)} 0;
`

export const StyledRow = styled(Row)`
  width: 100%;
  gap: ${toThemePixels(6)};

  > * {
    width: calc(50% - ${toThemePixels(3)});

    input {
      width: 100%;
    }
  }

  ${themeBreakpointDown(Breakpoint.sm)} {
    flex-direction: column;

    > * {
      width: 100%;
    }
  }
`

export const TextWithLinks = styled.div`
  width: 100%;
  font-size: 10px;

  button {
    display: inline-block;
    height: fit-content;
  }

  span {
    font-size: 10px;
  }
`

export const StreetRow = styled(Row)`
  gap: ${toThemePixels(4)};

  > * {
    &:first-child {
      width: 100%;
    }

    &:not(:first-child):last-child {
      input {
        min-width: unset;
        width: 100%;
      }
      width: calc((100% - 16px) / 4);
      flex-shrink: 0;
    }
  }
`

export const StyledLinkTypography = styled(Typography)`
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
`

export const CompanyFoundedDateDiscountTextContainer = styled(Column)`
  img {
    margin-top: ${toThemePixels(1)};
  }
`

export const ContactDataStepWrapper = styled(Column)`
  gap: 8px;
  height: 100%;
`
