import { ComponentColors, ThemeColor } from '@/enums'
import { BuildingIcon, MyProfileIcon } from '@/icons'
import { paths } from '@/routes/paths'
import { useGoToPath } from '@/utils'
import { useTranslation } from 'react-i18next'
import { useOutletContext } from 'react-router-dom'
import {
  ButtonLink,
  ContactExpert,
  PageMainContent,
  PageWidgetContainer,
  Typography,
} from '../../components'
import { LinkWithPermissions } from './components/LinkWithPermissions'
import { LinksContainer, SectionTitle, StyledSettingsIcon } from './styles'

export const Base = () => {
  const { t } = useTranslation()
  const { hasProtectedAccess } = useOutletContext<{
    hasProtectedAccess: boolean
  }>()

  return (
    <>
      <PageMainContent>
        <Typography variant="h4">{t('profile.menu.profile')}</Typography>
        <LinksContainer>
          <SectionTitle>
            <MyProfileIcon />
            <Typography color={ThemeColor.b50} variant="p1Body">
              {t('my_details')}
            </Typography>
          </SectionTitle>
          <ButtonLink
            color={ComponentColors.primary}
            onClick={useGoToPath(paths.myProfile)}
            size="large"
          >
            {t('profile.titles.profile')}
          </ButtonLink>
          <LinkWithPermissions
            color={ComponentColors.primary}
            disabled={!hasProtectedAccess}
            onClick={useGoToPath(paths.signPoa)}
            size="large"
          >
            {t('profile.menu.signPOA')}
          </LinkWithPermissions>
          <SectionTitle>
            <BuildingIcon />
            <Typography color={ThemeColor.b50} variant="p1Body">
              {t('companyPage.singleCompany')}
            </Typography>
          </SectionTitle>
          <ButtonLink
            color={ComponentColors.primary}
            onClick={useGoToPath(paths.users)}
            size="large"
          >
            {t('profile.menu.users')}
          </ButtonLink>
          <ButtonLink
            color={ComponentColors.primary}
            onClick={useGoToPath(paths.companies)}
            size="large"
          >
            {t('profile.menu.companies')}
          </ButtonLink>
          <LinkWithPermissions
            color={ComponentColors.primary}
            disabled={!hasProtectedAccess}
            onClick={useGoToPath(paths.payments)}
            size="large"
          >
            {t('profile.menu.payments')}
          </LinkWithPermissions>
          <SectionTitle>
            <StyledSettingsIcon />
            <Typography color={ThemeColor.b50} variant="p1Body">
              {t('account')}
            </Typography>
          </SectionTitle>
          <ButtonLink
            color={ComponentColors.primary}
            onClick={useGoToPath(paths.language)}
            size="large"
          >
            {t('profile.menu.language')}
          </ButtonLink>
          <ButtonLink
            color={ComponentColors.primary}
            onClick={useGoToPath(paths.referral)}
            size="large"
          >
            {t('recommendations.success.btnRecommend')}
          </ButtonLink>
        </LinksContainer>
      </PageMainContent>
      <PageWidgetContainer>
        <ContactExpert text={t('settings_advisor_text')} />
      </PageWidgetContainer>
    </>
  )
}
