import {
  BackComponent,
  Column,
  CompanyCard,
  CompanyCardHeader,
  CompanyCardItem,
  ContactExpert,
  Loader,
  PageMainContent,
  PageWidgetContainer,
  Typography,
} from '@/components'
import { ComponentColors, ThemeColor } from '@/enums'
import { paths } from '@/routes/paths'
import { useCompany } from '@/services'
import { useGoToPath } from '@/utils'
import { useTranslation } from 'react-i18next'
import { useOutletContext } from 'react-router-dom'
import { getCanEditCompany } from '../helpers'
import { ItemContainer, PageTitle } from '../styles'
import { LinkWithPermissions } from './LinkWithPermissions'
import { UserTypes } from '@/types/user-profile'
import { Company } from '@/types/company'

interface UserItemProps {
  name: string
  surname: string
  userTypes: UserTypes
}

export const UserItem = ({ name, surname, userTypes }: UserItemProps) => {
  const { t } = useTranslation()
  const { owner, MD } = userTypes

  const getRole = () => {
    if (MD) return t('profile.users.roles.md')
    if (owner) return t('profile.users.roles.owner')
    return t('profile.users.roles.user')
  }

  return (
    <CompanyCardItem>
      <ItemContainer>
        <Column gap="4px" width="100%">
          <Typography variant="captionR">{`${name} ${surname}`}</Typography>
          <Typography color={ThemeColor.b50} variant="badgeText">
            {getRole()}
          </Typography>
        </Column>
      </ItemContainer>
    </CompanyCardItem>
  )
}

interface ProfileUsersListProps {
  companyId: string
  companies: any[]
  name: string
}

const ProfileUsersList = ({
  companyId,
  companies,
  name,
}: ProfileUsersListProps) => {
  const { t } = useTranslation()
  const addNewUser = useGoToPath(paths.addUserWithKey(companyId))

  const onAddNewUser = () => addNewUser()

  const { data: company, isLoading } = useCompany(companyId)

  const canEditCompany = getCanEditCompany(companies, companyId)

  if (isLoading) return <Loader />

  return (
    <CompanyCard>
      <CompanyCardHeader>
        <ItemContainer>
          <Typography variant="p2Body">{name}</Typography>
          <LinkWithPermissions
            color={ComponentColors.primary}
            disabled={!canEditCompany}
            onClick={onAddNewUser}
            size="small"
          >
            {t('navigation.addUser')}
          </LinkWithPermissions>
        </ItemContainer>
      </CompanyCardHeader>
      {((company as unknown) as Company)?.users?.map(({ auth0, ...item }) => (
        <UserItem key={auth0} {...item} />
      ))}
    </CompanyCard>
  )
}

export const ProfileUsers = () => {
  const { t } = useTranslation()
  const { user } = useOutletContext<{
    user: { companies: Company[] }
  }>()

  return (
    <>
      <PageMainContent>
        <BackComponent />
        <PageTitle>
          <Typography variant="h4">{t('profile.menu.users')}</Typography>
        </PageTitle>
        <Column gap="16px">
          {user?.companies?.map((company: Company) => (
            <ProfileUsersList
              key={company.companyId}
              companies={user.companies}
              {...company}
            />
          ))}
        </Column>
      </PageMainContent>
      <PageWidgetContainer>
        <ContactExpert text={t('advisor_text_base')} />
      </PageWidgetContainer>
    </>
  )
}
