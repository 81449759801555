import { LineProgress, Loader, Typography } from '@/components'
import { BackContainer } from '@/components/organisms/Stepper/styles'
import {
  AssessmentStepKeys,
  Breakpoint,
  ComponentColors,
  ThemeColor,
} from '@/enums'
import { LeftArrow, SureinLogoNew } from '@/icons'
import { useBankingIntegrationSnackbar } from '@/modules/integrations/hooks/useBankingIntegrationSnackbar'
import { AdditionalNote } from '@/modules/yearlyCheck/components/steps/AdditionalNote'
import { BusinessDetails } from '@/modules/yearlyCheck/components/steps/BusinessDetails'
import { BusinessType } from '@/modules/yearlyCheck/components/steps/BusinessType'
import { CurrentInsurances } from '@/modules/yearlyCheck/components/steps/CurrentInsurances'
import { EmployeesCount } from '@/modules/yearlyCheck/components/steps/EmployeesCount'
import { LastYearFinances } from '@/modules/yearlyCheck/components/steps/LastYearFinances'
import { SuccessStep } from '@/modules/yearlyCheck/components/steps/SuccessStep'
import {
  currentStepLocalStorageKey,
  formValuesLocalStorageKey,
  makeLocalStorageKey,
} from '@/modules/yearlyCheck/helpers/makeLocalStorageKey'
import {
  StepperProvider,
  useStepper,
} from '@/modules/yearlyCheck/hooks/useStepper'
import { paths } from '@/routes/paths'
import { useAssessment } from '@/services'
import {
  dynamicObjectPropType,
  themeBreakpointUp,
  themeColor,
  toThemePixels,
  useGoToPath,
} from '@/utils'
import { useBankingIntegration } from '@/utils/hooks/useBankingIntegration'
import { isEmpty } from 'ramda'
import { useTranslation } from 'react-i18next'
import { useParams, useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import './index.css'

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${themeColor(ThemeColor.info4)};
`

const Container = styled.div`
  background-color: ${themeColor(ThemeColor.info4)};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  z-index: 20000;
  width: 100vw;
`

const DivWithFixedHeight = styled.div`
  height: 34px;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  background-color: ${themeColor(ThemeColor.b0)};
  padding: ${toThemePixels(4)} ${toThemePixels(4)};

  ${themeBreakpointUp(Breakpoint.md)} {
    background-color: ${themeColor(ThemeColor.info4)};
    border-bottom: 1px solid ${themeColor(ThemeColor.b30)};
    padding: ${toThemePixels(3)} ${toThemePixels(10)};
  }
`

const BackButton = styled(BackContainer)`
  margin: 0;
  span {
    white-space: nowrap;
  }
`

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;

  ${themeBreakpointUp(Breakpoint.md)} {
    padding: 24px;
  }
`

const FlexItemOne = styled.div`
  flex: 1;
`

const FlexItemCenter = styled.div`
  flex: 2;
  display: flex;
  justify-content: center;
`

const BaseStepper = ({ componentMapper, defaultValues }) => {
  const { t } = useTranslation()
  const { currentStep, onPrevious, onNext } = useStepper()
  const goToDashboard = useGoToPath(paths.dashboard)

  const steps = Object.keys(componentMapper)
  const renderCurrentStep = () => {
    const StepComponent = componentMapper[steps[currentStep]]
    if (!StepComponent) {
      return null
    }

    return (
      <StepComponent
        defaultValues={defaultValues[steps[currentStep]]}
        onNext={onNext}
      />
    )
  }

  const isLastStep = currentStep === steps.length - 1
  const isBackButtonVisible = currentStep > 0 && !isLastStep

  return (
    <PageContainer>
      <Header>
        <SureinLogoNew height={24} onClick={goToDashboard} width={88} />
      </Header>

      <SectionWrapper>
        <FlexContainer>
          <FlexItemOne>
            {isBackButtonVisible ? (
              <BackButton onClick={onPrevious}>
                <Typography
                  color={ThemeColor.b50}
                  singleLine
                  variant="captionR"
                >
                  <LeftArrow stroke={ThemeColor.b50} />
                  <span>{t('back')}</span>
                </Typography>
              </BackButton>
            ) : (
              <DivWithFixedHeight />
            )}
          </FlexItemOne>

          <FlexItemCenter>
            {!isLastStep && (
              <LineProgress
                percentage={((currentStep + 1) / steps.length) * 100}
                width="120px"
                color={ComponentColors.primary}
              />
            )}
          </FlexItemCenter>
          <FlexItemOne />
        </FlexContainer>
        {renderCurrentStep()}
      </SectionWrapper>
    </PageContainer>
  )
}

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${themeColor(ThemeColor.b0)};

  ${themeBreakpointUp(Breakpoint.md)} {
    min-width: 760px;
    max-width: 760px;
    margin: 24px auto;
    border-radius: 8px;
  }
`

BaseStepper.propTypes = {
  componentMapper: dynamicObjectPropType,
  defaultValues: dynamicObjectPropType,
}

// Note: the field order matters
const componentMapper = {
  business_type: BusinessType,
  company_details: BusinessDetails,
  employees_count: EmployeesCount,
  last_year_finances: LastYearFinances,
  notes: AdditionalNote,
  current_insurances: CurrentInsurances,
  successStep: SuccessStep,
}

const makeDefaultValues = (shouldFetchIntegrations, data, reports) => {
  if (!data) return {}
  if (shouldFetchIntegrations && !isEmpty(reports))
    return {
      [AssessmentStepKeys.businessType]:
        data?.values.businessType.businessType === 'other'
          ? {}
          : {
              businessType: data?.values.businessType.businessType,
              locationSubtypes: data?.values.businessType.locationSubtypes,
              locationType: data?.values.businessType.locationType,
              locationTypeSpecification:
                data?.values.businessType.locationTypeSpecification,
            },
      [AssessmentStepKeys.locationDetails]: {
        name: data?.values.locationDetails.name,
        address: data?.values.locationDetails.address,
      },
      [AssessmentStepKeys.employeesCount]: {
        fullTimeEmployeesCount: 0,
        miniJobbersCount: 0,
        ownersCount: 0,
        partTimeEmployeesCount: 0,
      },
      [AssessmentStepKeys.lastYearFinances]: {
        approxTurnover: reports.finance.avgRevenue,
        approxPersonelExpenses: reports.finance.avgPersonnelExpenses,
      },
    }
  return {
    [AssessmentStepKeys.businessType]:
      data?.values.businessType.businessType === 'other'
        ? {}
        : {
            businessType: data?.values.businessType.businessType,
            locationSubtypes: data?.values.businessType.locationSubtypes,
            locationType: data?.values.businessType.locationType,
            locationTypeSpecification:
              data?.values.businessType.locationTypeSpecification,
          },
    [AssessmentStepKeys.locationDetails]: {
      name: data?.values.locationDetails.name,
      address: data?.values.locationDetails.address,
    },
    [AssessmentStepKeys.employeesCount]: {
      fullTimeEmployeesCount: 0,
      miniJobbersCount: 0,
      ownersCount: 0,
      partTimeEmployeesCount: 0,
    },
  }
}

const YearlyAssessment = () => {
  const { locationId } = useParams()
  const [searchParams] = useSearchParams()
  const shouldFetchIntegrations = searchParams.get('withIntegrations')

  // Encapsulates the logic to show a snackbar message when the banking integration is aborted or successful
  useBankingIntegrationSnackbar()

  const { data } = useAssessment(locationId)

  const config = {
    source: 'yearly-check-in',
    metadata: { locationId },
  }

  const {
    isLoading: isBankingIntegrationLoading,
    reports,
  } = useBankingIntegration(config, shouldFetchIntegrations)

  const defaultValues = makeDefaultValues(
    shouldFetchIntegrations,
    data,
    reports,
  )

  const goToDashboard = useGoToPath(paths.dashboard)

  const onFinish = () => {
    localStorage.removeItem(
      makeLocalStorageKey(currentStepLocalStorageKey, locationId),
    )
    localStorage.removeItem(
      makeLocalStorageKey(formValuesLocalStorageKey, locationId),
    )
    goToDashboard()
  }

  if (isBankingIntegrationLoading) return <Loader />

  if (!data) return null

  return (
    <StepperProvider
      defaultValues={defaultValues}
      locationId={locationId}
      onFinish={onFinish}
      steps={componentMapper}
    >
      <Container>
        <BaseStepper
          componentMapper={componentMapper}
          defaultValues={defaultValues}
        />
      </Container>
    </StepperProvider>
  )
}

export default YearlyAssessment
