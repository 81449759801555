import { Banner, Column, FormUnit } from '@/components'
import { AssessmentStepKeys, ComponentColors } from '@/enums'
import { Info } from '@/icons'
import { makeBankTranslation } from '@/modules/integrations/helpers'
import {
  FormContent,
  StepContainer,
  StepFooter,
  StepHeader,
} from '@/modules/yearlyCheck/components/steps/components'
import { useStepper } from '@/modules/yearlyCheck/hooks/useStepper'
import { useSaveAssessmentStep } from '@/pages/SelfAssessmentPage/helpers'
import { FormProvider, useFormContext } from '@/providers'
import { useLanguage } from '@/utils'
import { useBankingIntegration } from '@/utils/hooks/useBankingIntegration'
import { isEmpty } from 'ramda'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import { makeLastYearFinancesValidationSchema } from '../../helpers/validationSchema'
import { useSegment } from '@/modules/analytics'
const InfoBannerContainer = styled(Column)`
  margin-bottom: 16px;
`

const config = {
  source: 'yearly-check-in',
}

const LastYearFinancesForm = () => {
  const { onNext } = useStepper()
  const { values } = useFormContext()
  const { t } = useTranslation()
  const language = useLanguage()
  const [searchParams] = useSearchParams()
  const analytics = useSegment()
  const shouldFetchIntegrations = searchParams.get('withIntegrations')
  const { save, isLoading: isButtonLoading } = useSaveAssessmentStep(
    AssessmentStepKeys.lastYearFinances,
    undefined,
    true,
  )
  const handleOnChange = () => {
    if (!values) return

    save().then(async () => {
      await analytics.track('user_app_yearly_check_last_year_finances')
      onNext(AssessmentStepKeys.lastYearFinances, values)
    })
  }

  const { reports } = useBankingIntegration(config, shouldFetchIntegrations)

  return (
    <>
      <StepHeader
        description={
          shouldFetchIntegrations &&
          reports &&
          t('yearlyAssessment.reportsDataFoundSubtitle', {
            bank: makeBankTranslation(language, reports?.general?.bankName),
          })
        }
        sectionLabel={t('yearlyAssessment.lastYearFinances-category')}
        title={t('yearlyAssessment.lastYearFinances-title')}
      />
      <FormContent>
        {shouldFetchIntegrations && isEmpty(reports) ? (
          <InfoBannerContainer>
            <Banner
              color={ComponentColors.danger}
              icon={Info}
              iconAlignment="top"
              text={t('yearlyAssessment.awaitingReportsInfo')}
            />
          </InfoBannerContainer>
        ) : null}
        <FormUnit
          initialValues={reports?.finance?.avgRevenue}
          label={t('yearlyAssessment.lastYearFinancesApproxTurnover')}
          name="approxTurnover"
          placeholder="200.000"
          unit="€"
        />
        <FormUnit
          initialValues={reports?.finance?.avgPersonnelExpenses}
          label={t('yearlyAssessment.lastYearFinancesApproxPersonelExpenses')}
          name="approxPersonelExpenses"
          placeholder="100.000"
          unit="€"
        />
      </FormContent>
      <StepFooter isButtonLoading={isButtonLoading} onSubmit={handleOnChange} />
    </>
  )
}

export const LastYearFinances = () => {
  const { formValues } = useStepper()
  const { t } = useTranslation()
  const validations = t('validations', { returnObjects: true })

  return (
    <StepContainer>
      <FormProvider
        initialValues={{
          approxPersonelExpenses:
            formValues.last_year_finances?.approxPersonelExpenses,
          approxTurnover: formValues.last_year_finances?.approxTurnover,
        }}
        validationSchema={makeLastYearFinancesValidationSchema(validations)}
      >
        <LastYearFinancesForm />
      </FormProvider>
    </StepContainer>
  )
}
