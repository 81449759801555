import { useSnackbar } from '@/providers'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

export const useBankingIntegrationSnackbar = (): void => {
  const snackbar = useSnackbar()
  const { state } = useLocation()
  const { t } = useTranslation()

  useEffect(() => {
    if (state?.bankingIntegrationsSuccess) {
      snackbar.success(t('integrationsPage.bankingConnectionSuccess'))
    } else if (state?.bankingIntegrationsError) {
      snackbar.error(t('integrationsPage.bankingConnectionAborted'))
    }
  }, [])
}
