import { Snackbar } from '@/components/atoms/Snackbar'
import { ComponentColors } from '@/enums'
import { oneOrManyChildren } from '@/utils'
import {
  createContext,
  useState,
  useContext,
  useCallback,
  ReactNode,
} from 'react'

type SnackbarCall = (content: string, durationMs?: number) => void

const SnackbarContext = createContext<
  | {
      success: SnackbarCall
      info: SnackbarCall
      error: SnackbarCall
      warning: SnackbarCall
    }
  | undefined
>(undefined)

export const SnackbarProvider = ({ children }: { children: ReactNode }) => {
  const [message, setMessage] = useState<string>()
  const [color, setColor] = useState<keyof typeof ComponentColors>()
  const [duration, setDuration] = useState<number>()

  const success = (content: string, durationMs = 5000) => {
    if (durationMs) setDuration(durationMs)
    setMessage(content)
    setColor(ComponentColors.success)
  }

  const info = (content: string, durationMs = 5000) => {
    if (durationMs) setDuration(durationMs)
    setMessage(content)
    setColor(ComponentColors.primary)
  }

  const error = (content: string, durationMs = 5000) => {
    if (durationMs) setDuration(durationMs)
    setMessage(content)
    setColor(ComponentColors.danger)
  }

  const warning = (content: string, durationMs = 5000) => {
    if (durationMs) setDuration(durationMs)
    setMessage(content)
    setColor(ComponentColors.warning)
  }

  const Component = useCallback(
    () => (
      <Snackbar color={color} duration={duration} onClose={setMessage}>
        {message}
      </Snackbar>
    ),
    [message, color],
  )

  return (
    <SnackbarContext.Provider value={{ success, info, error, warning }}>
      <Component />
      {children}
    </SnackbarContext.Provider>
  )
}

SnackbarProvider.propTypes = {
  children: oneOrManyChildren.isRequired,
}

export const useSnackbar = () => {
  const ctx = useContext(SnackbarContext)

  if (ctx === undefined) {
    throw new Error('useSnackbar must be used within a SnackbarContext')
  }
  return ctx
}
