import { Column, Row } from '@/components'
import { Breakpoint } from '@/enums'
import { themeBreakpointDown } from '@/utils'
import styled from 'styled-components'
import { ifProp, prop } from 'styled-tools'

export const KPIOverviewContainer = styled(Row)`
  width: 100%;
  border: 1px solid ${(props) => props.theme.color.b30};
  border-radius: 8px;
  align-items: center;
  gap: 24px;
  padding: 12px 24px;
  position: relative;

  @media (max-width: 1200px) {
    flex-direction: column;
    padding-bottom: 48px;
  }

  ${themeBreakpointDown(Breakpoint.md)} {
    flex-direction: column;
    padding-bottom: ${ifProp('hasDetailsLink', '48px', '12px')};
  }

  > button {
    position: absolute;
    bottom: 8px;
    right: 16px;
    svg {
      height: 10px;
      width: 10px;

      > path {
        stroke: ${(props) => props.theme.color.info1};
      }
    }
  }
`

export const KPIChartContainer = styled(Column)`
  height: 200px;
  width: 320px;
  max-height: 100%;
  border-radius: 8px;
  position: relative;
  justify-content: center;
  min-width: 0;
`

export const CustomTooltipComponent = styled(Row)`
  background-color: white;
  padding: 8px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  align-items: center;
  gap: 4px;

  > span {
    white-space: nowrap;
  }
`

export const KPILegendContainer = styled(Column)`
  width: 100%;
  justify-content: center;
  gap: 8px;
`

export const KPILegendItem = styled(Row)`
  justify-content: space-between;
  gap: 8px;
`

export const KPILegendColor = styled.div`
  background-color: ${prop('color')};
  height: 12px;
  width: 12px;
  border-radius: 50%;
  margin-right: 4px;
  flex-shrink: 0;
`

export const MiddleTextWrapper = styled(Column)`
  position: absolute;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  width: 100%;
  gap: 8px;
  pointer-events: none;

  > span:first-child {
    font-size: 10px;
    color: ${(props) => props.theme.color.b50};
  }

  > span:last-child {
    font-weight: 700;
    font-size: 10px;
    color: ${(props) => props.theme.color.b50};
  }
`
