import { formatMoney } from '@/utils'

export const chartColors: string[] = [
  '#010B65',
  '#0317CE',
  '#7987FF',
  '#A9AFE1',
  '#CFD4FF',
  '#818499',
]

interface Company {
  companyId: string
  name: string
  grossPrice: number
}

interface Other {
  grossPrice: number
}

export interface ChartData {
  id: string
  name: string
  label: string
  value: number
  color: string
}

export interface KPIsData {
  companies: Company[]
  other: Other
}

export const prepareChartData = ({
  companies,
  other,
}: KPIsData): ChartData[] => {
  const otherObject: ChartData = {
    id: 'other',
    name: 'Other',
    label: 'Other',
    value: other.grossPrice,
    color: chartColors[chartColors.length - 1],
  }

  const chartCompanies: ChartData[] = companies.map((item, index) => ({
    id: item.companyId,
    name: item.name,
    label: `${item.name} ${formatMoney(item.grossPrice)}`,
    value: item.grossPrice,
    color: chartColors[index],
  }))

  return other.grossPrice ? [...chartCompanies, otherObject] : chartCompanies
}

interface InternalChartText {
  integer: string
  decimal: string
}

export const getInternalChartText = (grossPrice: number): InternalChartText => {
  const integerPart = Math.floor(grossPrice)
  const decimalPart = Number(grossPrice - integerPart)
    .toFixed(2)
    .split('.')[1]

  return {
    integer: formatMoney(integerPart, 'euro', {
      style: 'decimal',
    }),
    decimal: decimalPart,
  }
}
