import { Column, Row } from '@/components'
import { themeBreakpointDown } from '@/utils'
import styled from 'styled-components'

export const Container = styled(Column)`
  grid-column: col-start 3 / span 9;
  gap: 16px;

  &:has(table) {
    grid-column: col-start 3 / span 10;
  }

  @media (max-width: 655px) {
    grid-column: col-start / span 2 !important;
  }
`

export const TableContainer = styled(Column)`
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid ${(props) => props.theme.color.b30};
  background-color: ${(props) => props.theme.color.b0};
  overflow-x: hidden;

  > div:first-child {
    width: 100%;
    padding: 16px 16px 0 16px;
  }

  > div:first-child span {
    white-space: normal;
  }

  th: has(> button) {
    padding-left: 8px;
  }
`

export const InsuranceEntryWrapper = styled(Row)`
  padding: 0 8px 0 0;
  cursor: pointer;
  align-items: center;
  gap: 8px;
  width: 240px;

  ${themeBreakpointDown('sm')} {
    padding: 16px 8px 0 0;
    min-width: 200px;
    flex-wrap: no-wrap;
  }
`

export const InsuranceEntryTextContainer = styled(Column)`
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 25ch;
  }
  svg {
    flex-shrink: 0;
    margin-left: 5px;
    > path {
      stroke: ${(props) => props.theme.color.b40};
    }
  }

  ${themeBreakpointDown('md')} {
    span {
      max-width: 25ch;
    }
  }
`

export const LocationTablesWrapper = styled(Column)`
  gap: 16px;
  margin-top: 8px;
  margin-bottom: 8px;
  display: none;

  &:has(table) {
    display: flex;
  }
`

export const LocationTableRightPrice = styled(Row)`
  align-items: center;
  gap: 4px;
  flex-wrap: wrap;
  justify-content: flex-end;
  text-align: right;
`

export const SearchBarContainer = styled.div`
  padding: 8px 0;
  min-width: 320px;
  max-width: 380px;
  width: 100%;

  input {
    background-color: ${(props) => props.theme.color.b0};
    width: 100%;
  }
`

export const TotalGrossPriceContainer = styled(Row)`
  align-items: center;
  gap: 12px;

  > span:first-child {
    max-width: 30ch;
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  > div > span:first-child {
    margin-right: 4px;
  }

  ${themeBreakpointDown('lg')} {
    > span:first-child {
      max-width: 15ch;
      display: inline-block;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  ${themeBreakpointDown('sm')} {
    flex-direction: column;
    align-items: flex-start;
  }
`

export const TableGrossPrice = styled.div`
  white-space: nowrap;
`

export const TableScrollWrapper = styled.div<{
  isEmpty: boolean
}>`
  width: 100%;
  overflow-x: scroll;
  position: relative;

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  & {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  td {
    height: ${(props) => props.isEmpty && '12rem'};
  }
`

export const EmptyContractsSearchContainer = styled(Column)`
  align-items: center;
  justify-content: center;
  gap: 12px;
  padding: 24px 0;
  text-align: center;
  display: flex;
  > div {
    max-width: 400px;
  }
`

export const CompanyAndLocationList = styled(Column)`
  :has(table, #skeleton-loader) {
    ${EmptyContractsSearchContainer} {
      display: none;
    }
  }
`

export const SwitchLabel = styled.label`
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 8px;

  > span {
    white-space: nowrap;
  }
`

export const ArchiveSwitchRow = styled(Row)`
  gap: 8px;
  align-items: center;

  > svg {
    cursor: pointer;
  }
`

export const EmptyStateContainer = styled(Column)`
  align-items: center;
  justify-content: center;
  gap: 12px;
  padding: 24px 0;
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  // * 49px is the height of the header + 1px border
  height: calc(100% - 49px);
  background-color: ${(props) => props.theme.color.b0};
`

export const InsuranceFinderButton = styled(Row)`
  align-items: center;
  gap: 4px;
  cursor: pointer;
`
