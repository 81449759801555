import { useTranslation } from 'react-i18next'
import {
  InsuranceGroup,
  insuranceGroupsWithKeys,
} from '../constants/insuranceGroupsWithKeys'
import { Categories } from '@/enums'

interface GroupedInsurance {
  title: string
  subtitle: string
}

export const useInsuranceGroups = () => {
  const { t } = useTranslation()
  const groupedInsurances: Record<
    keyof typeof Categories,
    GroupedInsurance
  > = t('groupedInsurances', { returnObjects: true })

  return insuranceGroupsWithKeys.map((group: InsuranceGroup) => ({
    groupName: t(`currentInsuranceGroups.${group.groupName}`),
    items: group.keys.map((key: keyof typeof Categories) => ({
      value: key,
      ...groupedInsurances[key],
    })),
  }))
}
