/* eslint-disable import/no-cycle */
import { ThemeColor } from '@/enums'
import { isNumber } from '@/utils'
import { useTheme } from 'styled-components'

const buttonSize = {
  width: 24,
  height: 24,
}

const titleSize = {
  width: 22,
  height: 22,
}

const infoSize = {
  width: 18,
  height: 18,
}

const menuSize = {
  width: 14,
  height: 14,
}

const headerSize = {
  width: 32,
  height: 32,
}

const tagSize = {
  width: 13,
  height: 13,
}

const messageSize = {
  width: 10,
  height: 10,
}

const sizeMap = {
  button: buttonSize,
  info: infoSize,
  menu: menuSize,
  header: headerSize,
  tag: tagSize,
  title: titleSize,
  message: messageSize,
} as const

export const withSvgProps = (
  Component: React.ComponentType<React.SVGProps<SVGSVGElement>>,
) => {
  const SvgWithProps = ({
    color,
    stroke = false,
    size = 'info',
    ...rest
  }: {
    color?: keyof typeof ThemeColor
    stroke?: boolean | string
    size?: keyof typeof sizeMap | number
  } & Partial<
    Omit<React.SVGProps<SVGSVGElement>, 'stroke' | 'color' | 'size'>
  >) => {
    const theme = useTheme()

    const fill = theme.color[color || ThemeColor.b100]
    const { height, width } = isNumber(size)
      ? { width: size, height: size }
      : sizeMap[size]
    const strokeColor = stroke ? fill : 'none'

    return (
      <Component
        fill={fill}
        height={height}
        stroke={strokeColor}
        width={width}
        {...rest}
      />
    )
  }

  SvgWithProps.displayName = `SvgWithProps(${Component.displayName})`

  return SvgWithProps
}
