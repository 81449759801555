import { api } from './api'

export interface PaymentInfo {
  iban: string
  bic: string | null
  priority: 0 | 1
  companyId: string
  createdAt: Date
  updatedAt: Date
  paymentId: string
  name: string | null
}

export type CreatePaymentInfo = Omit<
  PaymentInfo,
  'createdAt' | 'updatedAt' | 'paymentId'
>

export type UpdatePaymentInfo = Omit<CreatePaymentInfo, 'companyId'>

export const getCompanyPayments = (id: string) =>
  api.get(`/payment/getAll/${id}`)

export const createPayments = ({
  name,
  iban,
  bic,
  priority,
  companyId,
}: CreatePaymentInfo) =>
  api.post('/payment', { name, iban, bic, priority, companyId })

export const updatePayments = (
  id: string,
  { name, iban, bic, priority }: UpdatePaymentInfo,
) => api.put(`/payment/${id}`, { name, iban, bic, priority })

export const getPaymentsById = (id: string) => api.get(`/payment/getOne/${id}`)

export const deletePayments = (id: string) => api.delete(`/payment/${id}`)
