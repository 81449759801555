import { withSvgProps } from './helpers'
import { ReactComponent as SvgComponent } from './svg/download-line.svg'

type SvgProps = React.ComponentProps<typeof SvgComponent>

const Svg: React.FC<SvgProps> = (props) => <SvgComponent {...props} />

const Icon = withSvgProps(Svg)

export const DownloadLine: React.FC<React.ComponentProps<typeof Icon>> = (
  props,
) => <Icon {...props} fill="none" stroke />
