import { Address } from '@/types/address'

export const isGenericCompanyDetails = (
  companyName: string,
  address: Address,
): boolean => {
  return (
    companyName === 'company name' ||
    address?.city === 'city' ||
    address?.street === 'street' ||
    (address?.street === 'berlin' && address.streetNr === null)
  )
}
