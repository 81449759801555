import * as Sentry from '@sentry/react'
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios'
import { assoc, mergeLeft, pick } from 'ramda'

type GetHeadersFn = () => Record<string, string>

class ApiRequestError extends Error {
  constructor(baseUrl: string) {
    super(`Request to ${baseUrl} returned error`)
    this.name = 'ApiRequestError'
  }
}

export const createApiClient = (
  baseURL: string,
  getHeaders: GetHeadersFn = () => ({}),
): AxiosInstance => {
  const contentInterceptor = (config: AxiosRequestConfig) => {
    const headers = mergeLeft(config.headers, {
      'Content-Type': 'application/json',
      ...getHeaders(),
    })

    return assoc('headers', headers, config)
  }

  const responseErrorInterceptor = (error: AxiosError) => {
    const captureContext = {
      extra: {
        response: {
          config: pick(
            ['baseURL', 'url', 'data', 'method'],
            error.response?.config || {},
          ),
          ...(error.response
            ? pick(['status', 'statusText', 'data'], error.response)
            : {}),
        },
      },
    }
    Sentry.captureException(new ApiRequestError(baseURL), captureContext)
    return Promise.reject(error)
  }

  const multiTenantApi = axios.create({
    baseURL,
  })

  // TODO: add logging interceptor (betterstack)
  multiTenantApi.interceptors.request.use(contentInterceptor)
  multiTenantApi.interceptors.response.use(
    (item) => item,
    responseErrorInterceptor,
  )

  return multiTenantApi
}
