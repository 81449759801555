import { CheckoutOverviewStep } from './CheckoutOverviewStep'
import { KillerQuestionsStep } from './KillerQuestionsStep'
import { PaymentMethodStep } from './PaymentMethodStep'
import { SignPoaStep } from './SignPoaStep'
import { SuccessOrderStep } from './SuccessOrderStep'
import { CompanyDetailsStep } from './CompanyDetailsStep'

export const CheckoutStepKeys = {
  payment: 'payment',
  killerQuestions: 'killerQuestions',
  companyDetails: 'companyDetails',
  poa: 'poa',
  overview: 'overview',
  success: 'success',
}

export const checkoutSteps = {
  [CheckoutStepKeys.payment]: PaymentMethodStep,
  [CheckoutStepKeys.killerQuestions]: KillerQuestionsStep,
  [CheckoutStepKeys.companyDetails]: CompanyDetailsStep,
  [CheckoutStepKeys.poa]: SignPoaStep,
  [CheckoutStepKeys.overview]: CheckoutOverviewStep,
  [CheckoutStepKeys.success]: SuccessOrderStep,
}

export const checkoutStepIndexes = {
  [CheckoutStepKeys.payment]: 0,
  [CheckoutStepKeys.killerQuestions]: 1,
  [CheckoutStepKeys.companyDetails]: 2,
  [CheckoutStepKeys.poa]: 2,
  [CheckoutStepKeys.overview]: 3,
  [CheckoutStepKeys.success]: 4,
}
