import { FormInputField } from '@/components'
import { FormInputWithDropdown } from '@/components/molecules/FormItem/FormInputWithDropdown'
import { BusinessOrgs } from '@/enums'
import { useFormContext } from '@/providers'
import { themeBreakpointDown, useDebouncedCallback } from '@/utils'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { config } from '../../../config'
import PropTypes from 'prop-types'

const Container = styled.div`
  display: flex;
  gap: 1rem;

  ${themeBreakpointDown('md')} {
    flex-direction: column;
  }
`

const StreetField = styled.div`
  flex: 4;

  ${themeBreakpointDown('md')} {
    flex: none;
  }
`

function convertToOptions(values) {
  return Object.keys(values).map((key) => ({
    value: key,
    label: values[key],
  }))
}

const useAutocomplete = () => {
  const [dropdownOptions, setDropdownOptions] = useState([])
  const [autocompleteService, setAutocompleteService] = useState(null)
  const [placesService, setPlacesService] = useState(null)

  // ! We're aware that this is not the right way to handle this, and will be changed in a subsequent
  // ! this has to be refactored to not be a kickflip.
  const loadScript = (url, callback) => {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.src = url
    script.onload = callback
    document.head.appendChild(script)
  }

  useEffect(() => {
    if (!window.google) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${config.googlePlacesApiKey}&libraries=places`,
        () => {
          const autocompleteServiceInstance = new window.google.maps.places.AutocompleteService()
          setAutocompleteService(autocompleteServiceInstance)
          const placesServiceInstance = new window.google.maps.places.PlacesService(
            document.createElement('div'),
          )
          setPlacesService(placesServiceInstance)
        },
      )
    } else {
      const autocompleteServiceInstance = new window.google.maps.places.AutocompleteService()
      setAutocompleteService(autocompleteServiceInstance)
      const placesServiceInstance = new window.google.maps.places.PlacesService(
        document.createElement('div'),
      )
      setPlacesService(placesServiceInstance)
    }
  }, [])

  const fetchAutocompleteSuggestions = useDebouncedCallback((input) => {
    if (input.length < 3 || !autocompleteService) return

    autocompleteService.getPlacePredictions(
      { input, componentRestrictions: { country: 'DE' } },
      (predictions, status) => {
        if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
          console.error('Error fetching autocomplete suggestions:', status)
          return
        }

        const options = (predictions || []).slice(0, 5).map((prediction) => ({
          label: prediction.description,
          value: prediction.place_id,
        }))

        setDropdownOptions(options)
      },
    )
  }, 300)

  const getPlaceDetails = useCallback(
    (placeId, callback) => {
      if (!placesService) return

      placesService.getDetails({ placeId }, (place, status) => {
        if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
          console.error('Error fetching place details:', status)
          callback(null)
          return
        }

        callback(place)
      })
    },
    [placesService],
  )

  return {
    dropdownOptions,
    fetchAutocompleteSuggestions,
    getPlaceDetails,
  }
}

const prefixKey = (name, key) => (name ? `${name}.${key}` : key)

export const FormAddressAutocomplete = ({
  name,
  disabled,
  showErrorHelperText,
}) => {
  const { t } = useTranslation()
  const { setFieldValue } = useFormContext()
  const {
    dropdownOptions,
    fetchAutocompleteSuggestions,
    getPlaceDetails,
  } = useAutocomplete()

  const handleDropdownItemSelect = (option) => {
    const placeId = option.value
    getPlaceDetails(placeId, (place) => {
      if (place) {
        const addressComponents = place.address_components

        const getAddressComponent = (type) =>
          addressComponents.find((component) => component.types.includes(type))
            ?.long_name || ''

        const streetNumber = getAddressComponent('street_number')
        const route = getAddressComponent('route')
        const postalCode = getAddressComponent('postal_code')
        const city = getAddressComponent('locality')

        setFieldValue(prefixKey(name, 'name'), place.name)
        setFieldValue(prefixKey(name, 'address.street'), route)
        setFieldValue(prefixKey(name, 'address.streetNr'), streetNumber)
        setFieldValue(prefixKey(name, 'address.postalCode'), postalCode)
        setFieldValue(prefixKey(name, 'address.city'), city)
      }
    })
  }

  return (
    <>
      <FormInputWithDropdown
        dropDownOptions={dropdownOptions}
        label={t('businessName')}
        name={prefixKey(name, 'name')}
        onDropdownItemSelect={handleDropdownItemSelect}
        onSearchInput={fetchAutocompleteSuggestions}
        options={convertToOptions(BusinessOrgs)}
        showErrorHelperText={showErrorHelperText}
        disabled={disabled}
      />

      <Container>
        <StreetField>
          <FormInputField
            validateOnChange
            label={t('street')}
            name={prefixKey(name, 'address.street')}
            showErrorHelperText={showErrorHelperText}
            disabled={disabled}
          />
        </StreetField>
        <div className="flex-1">
          <FormInputField
            validateOnChange
            label={t('streetNr')}
            name={prefixKey(name, 'address.streetNr')}
            showErrorHelperText={showErrorHelperText}
            disabled={disabled}
          />
        </div>
      </Container>

      <FormInputField
        label={t('postCode')}
        name={prefixKey(name, 'address.postalCode')}
        type="number"
        showErrorHelperText={showErrorHelperText}
        disabled={disabled}
      />
      <FormInputField
        label={t('city')}
        name={prefixKey(name, 'address.city')}
        showErrorHelperText={showErrorHelperText}
        disabled={disabled}
      />
    </>
  )
}

FormAddressAutocomplete.propTypes = {
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  showErrorHelperText: PropTypes.bool,
}
