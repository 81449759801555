import * as Sentry from '@sentry/react'
import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios'
import { any, assoc, mergeLeft, pipe, propEq, prop, pick } from 'ramda'
import { camelToSnakeCase, snakeToCamelCase } from './case'
import { renameObjKeysBy } from './object'

type GetHeadersFn = () => Record<string, string>

class ApiRequestError extends Error {
  constructor(baseUrl: string) {
    super(`Request to ${baseUrl} returned error`)
    this.name = 'ApiRequestError'
  }
}

/**
 * @deprecated please do not use for new endpoints
 * This renames response keys from snake_case to camelCase - EVIL
 */
export const createMultiTenantApi = (
  baseURL: string,
  getHeaders: GetHeadersFn = () => ({}),
): AxiosInstance => {
  /**
   * @param {import('axios').AxiosResponse<any>} response
   */
  const responseInterceptor = (response: AxiosResponse<unknown>) =>
    pipe(
      prop('data'),
      renameObjKeysBy(snakeToCamelCase), // this is evil FIXME: let's remove it someday
    )(response)

  const contentInterceptor = (config: AxiosRequestConfig) => {
    const headers = mergeLeft(config.headers, {
      'Content-Type': 'application/json',
      ...getHeaders(),
    })

    return assoc('headers', headers, config)
  }

  const requestInterceptor = (config: AxiosRequestConfig) => {
    if (!config.data) return config

    const data = renameObjKeysBy(camelToSnakeCase, config.data)

    return assoc('data', data, config)
  }

  const responseErrorInterceptor = (error: AxiosError) => {
    const captureContext = {
      extra: {
        response: {
          config: pick(
            ['baseURL', 'url', 'data', 'method'],
            error.response?.config || {},
          ),
          ...(error.response
            ? pick(['status', 'statusText', 'data'], error.response)
            : {}),
        },
      },
    }
    Sentry.captureException(new ApiRequestError(baseURL), captureContext)
    return Promise.reject(error)
  }

  const multiTenantApi = axios.create({
    baseURL,
  })

  // TODO: add logging interceptor (betterstack)
  multiTenantApi.interceptors.request.use(contentInterceptor)
  multiTenantApi.interceptors.request.use(requestInterceptor)
  multiTenantApi.interceptors.response.use(
    responseInterceptor,
    responseErrorInterceptor,
  )

  return multiTenantApi
}

export const unauthorizedInterceptor = (logout: () => void) => (
  error: AxiosError,
) => {
  if (error.response?.status && [401, 403].includes(error.response?.status)) {
    // below throw is probably a mistake - logout is not returning anything
    throw logout()
  } else {
    throw error
  }
}

export const authInterceptor = (token: string) => (
  conf: AxiosRequestConfig,
) => {
  const headers = mergeLeft(conf.headers, {
    Authorization: `Bearer ${token}`,
  })

  return assoc('headers', headers, conf)
}

export const isLoading = propEq(true, 'isLoading')

export const areLoading = any(isLoading)
