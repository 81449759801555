import { Categories } from '@/enums'
import { Row } from '@surein/ui'
import { TFunction } from 'i18next'
import { Insurance } from './Page'

export const getCategoryName = (categoryId: string, t: TFunction) => {
  return t(
    Categories[categoryId as keyof typeof Categories]
      ? `categories.${
          Categories[categoryId as keyof typeof Categories].key
        }.name`
      : 'common.insurance',
  )
}

export const dateSortingFn = (
  rowA: Row<Insurance>,
  rowB: Row<Insurance>,
  columnId: string,
) => {
  const valueA = rowA.original[columnId as keyof Insurance]
  const valueB = rowB.original[columnId as keyof Insurance]

  if (!valueA && !valueB) return 0
  if (!valueA) return -1
  if (!valueB) return 1

  const dateA = new Date(valueA as string).getTime()
  const dateB = new Date(valueB as string).getTime()

  return dateA - dateB
}

export type ContractStatus =
  | 'aktiv'
  | 'storniert'
  | 'ruhend'
  | 'ablauf'
  | 'antrag'
  | 'ablehnung'
  | 'review'
  | 'validated'
  | 'transfer-initiated'
