import { toThemePixels } from '@/utils'
import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: ${toThemePixels(4)};
  border: 1px solid ${(props) => props.theme.color.b30};
  border-radius: ${toThemePixels(3)};
  gap: ${toThemePixels(2)};
`
