import styled from 'styled-components'
import { Row } from '../../styled'

export const Container = styled(Row)`
  max-width: 510px;
  gap: 8px;

  img {
    width: 32px !important;
    height: 32px !important;
    border-radius: 50%;
    object-fit: cover;
  }
`

export const TextBlock = styled(Row)`
  max-width: 400px;
  padding: 8px;
  background-color: ${(props) => props.theme.color.b10};
  border-radius: 0 8px 8px 8px;
`
