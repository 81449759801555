import { api } from './api'

interface CompleteYearlyCheckBody {
  createdInsuranceIds: string[]
}

export const completeYearlyCheck = (
  locationId: string,
  body: CompleteYearlyCheckBody,
) => api.put(`/self-assessment/yearly-check/complete/${locationId}`, body)
