import { GetSelfAssessmentApiResponse } from '@/api/assessment'
import { GetRecommendationByIdApiResponse } from '@/api/recommendations'
import {
  authInterceptor,
  createMultiTenantApi,
  getLocalStorageItem,
  makeQueryString,
  unauthorizedInterceptor,
} from '@/utils'
import { identity } from 'ramda'
import { config } from '../config'

const publicApi = createMultiTenantApi(config.backend)

// TODO: Find out why the BE is ignoring the confirmationId and adjust endpoint accordingly
// * Is confirmationId just a remnant of unconditional offer?
export const getPublicFile = ({
  confirmationId,
  fileId,
}: {
  confirmationId: string // FIXME: this is ignored by the BE
  fileId: string
}): Promise<{
  file: string
  type: string
  displayName: string
}> => publicApi.get(`/public/recommendations/${confirmationId}/files/${fileId}`)

// TDOO: fix any
export const createAccountV2 = ({
  email,
  name,
  surname,
  phone,
  categoryId,
  assessmentValues,
  partner,
  marketingPid,
  marketingMid,
}: any): Promise<{ accessToken: string }> =>
  publicApi.post('/v2/public/recommendations/create-account', {
    email,
    name,
    surname,
    phone,
    assessmentValues: {
      categoryId,
      ...assessmentValues,
    },
    partner,
    marketingPid,
    marketingMid,
  })

// TODO: fix any
export const createLead = ({
  email,
  name,
  surname,
  phone,
  assessmentValues,
  address,
  categoryId,
  partner,
}: any) =>
  publicApi.post(`v2/public-lead/${categoryId}`, {
    email,
    name,
    surname,
    phone,
    assessmentValues,
    address,
    funnelSource: window.location.host,
    partner,
  })

export const getTempToken = () => getLocalStorageItem('access-token')

const tempAuthApi = createMultiTenantApi(config.backend)

export const setTempAuthToken = (token: string) =>
  tempAuthApi.interceptors.request.use(authInterceptor(token))

export const setTempUnauthorizedInterceptor = (logout: () => void) =>
  tempAuthApi.interceptors.response.use(
    identity,
    unauthorizedInterceptor(logout),
  )

export const validateTemporaryToken = () =>
  tempAuthApi.get('/public/checkout/validate-token')

type ConditionalRecommendation = {
  id: string
  recommendation: GetRecommendationByIdApiResponse & { isSuccessful: boolean }
  assessmentValues: GetSelfAssessmentApiResponse['values']
}

export const createConditionalRecommendation = (): Promise<ConditionalRecommendation> =>
  tempAuthApi.post('/v2/public/recommendations')

export const retrieveConditionalRecommendation = (
  recommendationId: string,
): Promise<ConditionalRecommendation> =>
  tempAuthApi.get(`/v2/public/recommendations/${recommendationId}`)

export const getPublicCart = () => tempAuthApi.get('/public/checkout/cart')

export const updatePublicCart = (recommendationProductId: string) =>
  tempAuthApi.post('/public/checkout/cart', {
    recommendationProductId,
  })

export const getPublicKillerQuestionsKeys = (categoryId: string) =>
  tempAuthApi.get(
    `/public/checkout/killer-questions/questions${makeQueryString({
      categoryId,
    })}`,
  )

export const getPublicCheckoutKillerQuestions = () =>
  tempAuthApi.get('/public/checkout/killer-questions')

// TODO: fix any
export const updatePublicCheckoutKillerQuestions = (values: any) =>
  tempAuthApi.post('/public/checkout/killer-questions', values)

export const getPublicCheckoutPaymentMethod = () =>
  tempAuthApi.get('/public/checkout/payment-methods')

// TODO: fix any
export const createPublicCheckoutPaymentMethod = ({ name, iban }: any) =>
  tempAuthApi.post('/public/checkout/payment-methods', { name, iban })

// TODO: fix any
export const updatePublicCheckoutPaymentMethod = ({
  paymentId,
  name,
  iban,
}: any) =>
  tempAuthApi.put(`/public/checkout/payment-methods/${paymentId}`, {
    name,
    iban,
  })

export const getPublicCheckoutCompany = (): Promise<{
  // TODO: this response contains more properties - this was implemented to silence error in the services
  name: string
  address: {
    city: string
    street: string
  }
}> => tempAuthApi.get('/public/checkout/company-details')

// TODO: fix any
export const updatePublicCheckoutCompany = ({ name, address }: any) =>
  tempAuthApi.post('/public/checkout/company-details', { name, address })

export const getPublicCheckoutOverview = () =>
  tempAuthApi.get('/public/checkout/overview')

// TODO: fix any
export const updatePublicCheckoutOverview = ({
  startDate,
  recommendationProductId,
}: any) =>
  tempAuthApi.post('/public/checkout/overview', [
    { startDate, recommendationProductId },
  ])

export const getPublicCheckoutPoa = () =>
  tempAuthApi.get('/public/checkout/poa')

// TODO: fix any
export const createPublicCheckoutPoa = ({ birthdate, signature }: any) =>
  tempAuthApi.post('/v2/public/checkout/poa', { birthdate, signature })

export const doPublicCheckout = () => tempAuthApi.post('/public/checkout')
