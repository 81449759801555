import { Column, Row } from '@/components'
import { Breakpoint } from '@/enums'
import { SettingsIcon } from '@/icons'
import { themeBreakpointDown } from '@/utils'
import styled from 'styled-components'

export const CheckboxWrapper = styled.div`
span {
  font-size: 14px;
  font-weight: 400;
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 32px 0;
`

export const ReferralWrapper = styled(Column)`
  width: 100%;
`

export const ProfileButtonsWrapper = styled.div`
  display: flex;
  max-width: 100%;
  justify-content: end;

  ${themeBreakpointDown(Breakpoint.sm)} {
    flex-wrap: wrap;
    justify-content: start;
  }
`

export const LinksContainer = styled(Column)`
  margin-top: 32px;
  gap: 16px;

  > * {
    justify-content: start;
    height: unset;
    padding-left: 32px;
  }

  span {
    font-weight: 400;
  }

  ${themeBreakpointDown(Breakpoint.sm)} {
    span {
      font-size: 16px;
    }
  }
`

export const PageTitle = styled.div`
  margin-bottom: 32px;
`

export const PageHeader = styled(Column)`
  gap: 16px;
  margin-bottom: 32px;
`

export const ButtonContainer = styled(Column)`
  gap: 12px;
`

export const ItemContainer = styled(Row)`
  width: 100%;
  align-items: center;
  justify-content: space-between;

  button {
    height: unset;
  }
`

export const IntegratedScreenWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
`

export const IntegrationsButtonContainer = styled.div`
  max-width: 24rem;
  ${themeBreakpointDown(Breakpoint.md)} {
    width: 100%;
  }
`

export const IntegratedBankAccountContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`

export const IntegratedBankAccountData = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
`

export const SectionTitle = styled(Row)`
  gap: 12px;
  align-items: center;
  padding: 8px 0;
  margin-bottom: 8px;
  border-bottom: 1px solid ${(props) => props.theme.color.b30};
  max-width: 250px;

  svg {
    fill: transparent;
  }
`

export const StyledSettingsIcon = styled(SettingsIcon)`
  fill: ${(props) => props.theme.color.b50} !important;
`

export const ButtonLinkWithPermissionsWrapper = styled.div`
  position: relative;
  display: inline-block;

  button {
    height: unset;
  }
`
