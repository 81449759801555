import {
  BackComponent,
  ButtonLink,
  Column,
  CompanyCard,
  CompanyCardHeader,
  CompanyCardItem,
  ContactExpert,
  PageMainContent,
  PageWidgetContainer,
  Row,
  Typography,
} from '@/components'
import { ComponentColors, ThemeColor } from '@/enums'
import { SuccessIconLine } from '@/icons'
import { mapWithKey, useGoToPath } from '@/utils'
import { useTranslation } from 'react-i18next'
import { useOutletContext } from 'react-router-dom'
import { ItemContainer, PageHeader } from '../styles'
import { UserProfile } from '@/types/user-profile'

interface CompanyWrapperProps {
  userType: {
    MD: boolean
  }
  companyId: string
  name: string
  poaSigned: boolean
}

interface OutletContext {
  user: UserProfile
}

const CompanyWrapper = ({
  userType,
  companyId,
  name,
  poaSigned,
}: CompanyWrapperProps) => {
  const { t } = useTranslation()
  const goToCompanyPOA = useGoToPath(`/company/poa/${companyId}`)

  return (
    <CompanyCard>
      <CompanyCardHeader>
        <ItemContainer>
          <Typography variant="captionR">{name}</Typography>
          {userType.MD && !poaSigned && (
            <ButtonLink
              color={ComponentColors.primary}
              onClick={goToCompanyPOA}
              size="small"
            >
              {t('profile.menu.signPOA')}
            </ButtonLink>
          )}
        </ItemContainer>
      </CompanyCardHeader>
      <CompanyCardItem>
        <Row alignItems="center" gap="4px">
          {poaSigned && <SuccessIconLine size="tag" />}
          <Typography color={ThemeColor.b50} variant="p2Body">
            {poaSigned
              ? t('poaSignPage.poaSigned')
              : t('poaSignPage.poaNotSigned')}
          </Typography>
        </Row>
      </CompanyCardItem>
    </CompanyCard>
  )
}

export const ProfilePOA = () => {
  const { user } = useOutletContext<OutletContext>()
  const { t } = useTranslation()

  return (
    <>
      <PageMainContent>
        <BackComponent />
        <PageHeader>
          <Typography variant="h4">{t('profile.menu.signPOA')}</Typography>
          <Typography color={ThemeColor.b50} variant="p1Body">
            {t('poaSignPage.prePageSubtitle')}
          </Typography>
        </PageHeader>
        <Column gap="16px">
          {mapWithKey(CompanyWrapper, 'companyId', user?.companies)}
        </Column>
      </PageMainContent>
      <PageWidgetContainer>
        <ContactExpert text={t('advisor_text_base')} />
      </PageWidgetContainer>
    </>
  )
}
