import { Column, ModalBase, Typography } from '@/components'
import { CloseModalButton } from '@/components/molecules/ModalBase/styles'
import { Breakpoint, ThemeColor } from '@/enums'
import { Close, PiggyBank } from '@/icons'
import { HouseLightning } from '@/icons/HouseLightning'
import { PulseIcon } from '@/icons/Pulse'
import InsuranceAndProviderSelectorFormField from '@/modules/yearlyCheck/components/InsuranceAndProviderSelector'
import { useStepper } from '@/modules/yearlyCheck/hooks/useStepper'
import { FormProvider, useFormContext, useSnackbar } from '@/providers'
import {
  useCompleteYearlyCheck,
  useCreateMultipleInsurances,
  useUploadInsuranceFiles,
} from '@/services'
import { themeBreakpointUp, useIsMobile, useModal } from '@/utils'
import { Button } from '@surein/ui'
import { Plus } from 'lucide-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import {
  FixedFooter,
  FormContent,
  StepContainer,
  StepFooter,
  StepHeader,
} from './components'
import { useSegment } from '@/modules/analytics'

const FormContainer = styled(Column)`
  gap: 1rem;
`

const AddInsuranceForm = ({ onClose }) => {
  const { t } = useTranslation()
  const { onNext } = useStepper()
  const { values } = useFormContext()
  const { locationId } = useParams()
  const analytics = useSegment()

  const createMultipleInsurancesMutation = useCreateMultipleInsurances()
  const uploadInsuranceFilesMutation = useUploadInsuranceFiles('')
  const completeYearlyAssessmentMutation = useCompleteYearlyCheck(locationId)
  const snackbar = useSnackbar()

  const isLoading =
    createMultipleInsurancesMutation.isLoading ||
    uploadInsuranceFilesMutation.isLoading

  const handleOnChange = async () => {
    if (!values) return
    const createdInsuranceIds = []

    try {
      for (const insurance of values.insurances) {
        const { created } = await createMultipleInsurancesMutation.mutateAsync({
          locationId,
          data: [insurance],
        })

        const createdInsuranceId = created[0]
        createdInsuranceIds.push(createdInsuranceId)

        if (insurance.files?.length > 0 && createdInsuranceId) {
          await uploadInsuranceFilesMutation.mutateAsync({
            id: createdInsuranceId,
            files: insurance.files,
            category: 'contract',
          })
        }

        await analytics.track('user_app_yearly_check_contract_upload', {
          categoryId: insurance.categoryId,
          policyNumber: Boolean(insurance.policyNumber),
          documentsUploaded: Boolean(insurance.files?.length > 0),
        })
      }

      await completeYearlyAssessmentMutation.mutateAsync({
        createdInsuranceIds: createdInsuranceIds,
      })

      await analytics.track('user_app_yearly_check_completed')

      onNext()
    } catch (error) {
      console.error('Failed to complete yearly assessment:', error)
      snackbar.error(t('snackbars.error.generalMsg'))
    }
  }

  return (
    <>
      <StepHeader
        description={t('yearlyAssessment.addInsuranceForm-description')}
        title={t('yearlyAssessment.addInsuranceForm-title')}
      />
      <CloseModalButton onClick={onClose}>
        <Close color={ThemeColor.b100} height={16} width={16} />
      </CloseModalButton>
      <FormContainer>
        <FormContent>
          <InsuranceAndProviderSelectorFormField />
        </FormContent>
        <StepFooter
          fixed
          isButtonLoading={isLoading}
          onSubmit={handleOnChange}
        />
      </FormContainer>
    </>
  )
}
AddInsuranceForm.propTypes = {
  onClose: PropTypes.func,
}

const Footer = styled(FixedFooter)`
  gap: 1rem;
`

const stats = [
  {
    title: 'optimiseCoverageModal-stats-1',
    icon: PiggyBank,
  },
  {
    title: 'optimiseCoverageModal-stats-2',
    icon: HouseLightning,
  },
  {
    title: 'optimiseCoverageModal-stats-3',
    icon: PulseIcon,
  },
]

const InsuranceStat = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 16px;
`

const InsuranceSplash = ({ onContinue }) => {
  const { t } = useTranslation()
  const { isMobile } = useIsMobile()
  const { locationId } = useParams()

  const completeYearlyAssessmentMutation = useCompleteYearlyCheck(locationId)

  const snackbar = useSnackbar()
  const { onNext } = useStepper()

  const handleSkip = async () => {
    try {
      await completeYearlyAssessmentMutation.mutateAsync({
        createdInsuranceIds: [],
      })

      onNext()
    } catch (error) {
      console.error('Failed to complete yearly assessment:', error)
      snackbar.error(t('snackbars.error.generalMsg'))
    }
  }

  return (
    <>
      <StepHeader
        description={t('yearlyAssessment.currentInsurance-description')}
        sectionLabel={t('yearlyAssessment.currentInsurance-category')}
        title={t('yearlyAssessment.currentInsurance-title')}
      />

      <FormContent>
        {stats.map(({ icon, title }) => {
          const Icon = icon
          return (
            <InsuranceStat key={`optimise-coverage-modal-${icon}`}>
              <Icon color={ThemeColor.b100} height={24} stroke width={24} />
              <Column>
                <Typography bold variant="p1Body">
                  {t(`yearlyAssessment.${title}.title`)}
                </Typography>
                <Typography color={ThemeColor.b50} variant="p2Body">
                  {t(`yearlyAssessment.${title}.description`)}
                </Typography>
              </Column>
            </InsuranceStat>
          )
        })}
      </FormContent>
      <Footer>
        <Button onClick={handleSkip} variant="muted" shape="square">
          {t(
            isMobile
              ? 'yearlyAssessment.currentInsurance-skipButton-mobile'
              : 'yearlyAssessment.currentInsurance-skipButton',
          )}
        </Button>
        <Button
          suffixIcon={Plus}
          onClick={onContinue}
          shape="square"
          variant="primary"
        >
          {t('yearlyAssessment.currentInsurance-addButton')}
        </Button>
      </Footer>
    </>
  )
}

InsuranceSplash.propTypes = {
  onContinue: PropTypes.func,
}

export const CurrentInsurances = ({ defaultValues }) => {
  const { onNext } = useStepper()
  const { isOpen, open, close } = useModal()

  return (
    <Container>
      <StepContainer>
        <FormProvider initialValues={{ insurances: defaultValues }}>
          <InsuranceSplash onContinue={open} onSkip={onNext} />
          <ModalBase closable isOpen={isOpen} padding="0">
            <AddInsuranceForm onClose={close} />
          </ModalBase>
        </FormProvider>
      </StepContainer>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  height: calc(100vh - 102px);
  flex-direction: column;

  ${themeBreakpointUp(Breakpoint.md)} {
    height: 77vh;
  }
`

CurrentInsurances.propTypes = {
  defaultValues: PropTypes.array,
}
