import {
  BackComponent,
  Loader,
  PageMainContent,
  SignPoaView,
} from '@/components'
import { isGenericCompanyDetails } from '@/modules/company/utils/isGenericCompanyDetails'
import { useSnackbar } from '@/providers'
import { paths } from '@/routes/paths'
import { Redirect } from '@/routes/Redirect'
import { useCompany } from '@/services'
import {
  useGoBack,
  useGoToPath,
  useHistoryState,
  useTranslationSectionObject,
} from '@/utils'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

const useMessages = () => {
  const { t } = useTranslation()
  const snackBars = useTranslationSectionObject('snackbars')

  return {
    success: snackBars.success.onPoaSigned,
    cancel: t('cancel'),
  }
}

export const PageForm = () => {
  const { companyId } = useParams()
  const { from } = useHistoryState()

  const goAfterSubmit = useGoToPath(from || paths.dashboard)
  const messages = useMessages()

  const goBack = useGoBack()
  const snackbar = useSnackbar()

  const sendPOA = () => {
    snackbar.success(messages.success)
    goAfterSubmit()
  }

  return (
    <>
      <BackComponent />
      <SignPoaView
        cancelText={messages.cancel}
        companyId={companyId}
        onCancel={goBack}
        onSuccess={sendPOA}
      />
    </>
  )
}

export const PoaSignPage = () => {
  const { companyId } = useParams()

  const { data: company, isLoading, isFetching } = useCompany(companyId)
  if (isLoading || isFetching) return <Loader />

  const isGenericCompany = isGenericCompanyDetails(
    company?.name,
    company?.address,
  )

  if (company.poaSigned) return <Redirect to={paths.dashboard} />

  if (isGenericCompany)
    return (
      <Redirect
        state={{ from: `/company/poa/${companyId}` }}
        to={paths.editCompanyWithKey(companyId)}
      />
    )

  return (
    <PageMainContent>
      <PageForm company={company} />
    </PageMainContent>
  )
}
