import {
  oneOrManyChildren,
  oneOrManyChildrenOfType,
  parseChildrenArray,
  useChangeSearchParam,
  useSearchParams,
} from '@/utils'
import PropTypes from 'prop-types'
import { find, identity, propEq } from 'ramda'
import { useEffect, useRef, useState } from 'react'
import { applyTabInkStyles } from './helpers'
import { Container, TabInk, TabList, TabsWrapper } from './styles'
import { TabNode } from './TabNode'

export const TabPanel = ({ queryParamKey = 'tab', ...props }) => {
  const queryParamValue = useSearchParams()
  const changeStepParam = useChangeSearchParam()

  const handleTabChange = (tabKey) => {
    changeStepParam({ [queryParamKey]: tabKey })
  }

  return (
    <TabPanelBase
      {...props}
      defaultActiveKey={queryParamValue[queryParamKey]}
      onChange={handleTabChange}
    />
  )
}

export const TabPanelBase = ({
  defaultActiveKey,
  onChange,
  children,
  withContainer = identity,
}) => {
  const tabPanelRef = useRef()
  const tabs = parseChildrenArray(children)
  const [activeKey, setActiveKey] = useState(defaultActiveKey || tabs[0]?.key)

  useEffect(() => {
    setActiveKey(defaultActiveKey)
  }, [defaultActiveKey])

  const activeTab = find(propEq(activeKey, 'key'), tabs) || tabs[0]

  const handleTabClick = (key) => {
    if (key !== activeKey) {
      setActiveKey(key)
      onChange?.(key)
    }
  }

  useEffect(() => {
    applyTabInkStyles(tabPanelRef.current, tabs, activeKey)
  }, [activeKey, tabs])

  return (
    <Container>
      <TabList ref={tabPanelRef}>
        <TabsWrapper>
          {tabs.map(({ key, badge, text }) => (
            <TabNode
              key={key}
              active={activeTab.key === key}
              badge={badge}
              handleClick={handleTabClick}
              tabKey={key}
              text={text}
            />
          ))}
        </TabsWrapper>
        <TabInk />
      </TabList>
      {withContainer(activeTab?.children)}
    </Container>
  )
}

export const TabPane = () => null

/* eslint-disable react/no-unused-prop-types */
TabPane.propTypes = {
  badge: PropTypes.number,
  children: oneOrManyChildren,
  text: PropTypes.elementType.isRequired,
}
/* eslint-enable react/no-unused-prop-types */

TabPanelBase.propTypes = {
  children: oneOrManyChildrenOfType(TabPane),
  defaultActiveKey: PropTypes.string,
  onChange: PropTypes.func,
  withContainer: PropTypes.func,
}

TabPanel.propTypes = {
  queryParamKey: PropTypes.string,
}
