import { Column, ImageFromCdn, Row } from '@/components'
import { Breakpoint } from '@/enums'
import { themeBreakpointDown, themeBreakpointUp } from '@/utils'
import styled from 'styled-components'
import { ifProp, prop } from 'styled-tools'

export const Container = styled(Column)`
  padding: 0 32px;

  ${themeBreakpointDown(Breakpoint.sm)} {
    padding: 0;
  }
`

export const LocationCardContent = styled(Row)`
  gap: 4px;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  cursor: pointer;
`

export const PageHeader = styled(Column)`
  gap: 4px;
  margin-bottom: 24px;
`

export const DashboardCompanyCard = styled(Column)<{
  isHeadquarters: boolean
}>`
  gap: 8px;
  width: 100%;
  border-radius: 16px;
  overflow: hidden;
  padding: 16px;
  background: ${(props) =>
    props.isHeadquarters
      ? 'linear-gradient(90deg, #e6e8ff 0%, #f5f6ff 100%)'
      : props.theme.color.b10};
`

export const DashboardCompanyCardHeader = styled(Column)`
  gap: 8px;
  margin-bottom: ${ifProp('isHeadquarters', '8px', 0)};
  > h6 {
    margin-bottom: ${ifProp('isHeadquarters', 0, '8px')};
  }
`

export const DashboardCompanyCardItem = styled(Row)`
  padding: 16px;
  background-color: ${(props) => props.theme.color.b0};
  border: 1px solid ${(props) => props.theme.color.b30};
  border-radius: 12px;
  gap: 24px;

  > * {
    width: 100%;
  }
`
export const StyledHeadquarterLogoContainer = styled(Column)`
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  background: white;
`

export const StyledHeadquarterLogo = styled(ImageFromCdn)`
  max-height: 65%;
  max-width: 65%;
`

export const HeadquartersColumn = styled(Column)`
  gap: 4px;
`
export const CompaniesColumn = styled(Column)`
  gap: 16px;
  margin-top: ${ifProp('hasHeadquarters', '32px', 0)};
  > h5 {
    margin-bottom: 0;
  }
`

export const EmptyCompanySearchContainer = styled(Column)`
  align-items: center;
  justify-content: center;
  gap: 12px;
  padding: 24px 0;
  text-align: center;

  > button svg {
    margin-left: 4px;

    > path {
      stroke: ${(props) => props.theme.color.electric500};
    }
  }
`

export const ProSection = styled(Column)`
  gap: 24px;
`

export const ProHeader = styled(Row)`
  height: 10vw;
  width: 100%;
  min-height: 140px;
  border-radius: 8px;
  padding: 16px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 100%), url(${prop(
    'logoUrl',
  )});,
    lightgray 50%;
  background-size: cover;
  justify-content: space-between;
  align-items: center;

  > div:first-child {
    align-self: end;
  }

  ${themeBreakpointUp(Breakpoint['2xl'])} {
    height: 5vw;
}
`

export const ProHeaderTextContainer = styled(Column)`
  justify-content: flex-end;
  overflow: hidden;
  margin-bottom: ${ifProp('hasLogo', 0, '24px')};

  > span {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

export const ProHeaderLogoWrapper = styled(Row)`
  width: 8vw;
  height: 8vw;
  min-height: 100px;
  min-width: 100px;
  justify-content: center;
  align-items: center;
  padding: 16px;
  background: ${(props) => props.theme.color.b0};
  border-radius: 50%;
  aspect-ratio: 1/1;
  margin-right: 40px;
  > img {
    width: 80%;
    height: 80%;
  }

  ${themeBreakpointDown(Breakpoint.md)} {
    margin-right: 0;
  }

  ${themeBreakpointUp(Breakpoint['2xl'])} {
    height: 4vw;
    width: 4vw;
  }
`
