import { Breakpoint, ComponentColors } from '@/enums'
import {
  componentColorToPrimaryColorMap,
  themeBreakpointDown,
  toThemePixels,
} from '@/utils'
import styled, { keyframes } from 'styled-components'
import { ifProp } from 'styled-tools'

const leaving = keyframes`
  100% {
      opacity: 0;
  }
`

const coming = keyframes`
  from {
    transform: translate(-50%,-60px);
  }
`
const comingUp = keyframes`
  from {
    transform: translateY(-60px);
  }
`

export const Container = styled.div<{
  color?: keyof typeof ComponentColors
  leaving: boolean
}>`
  z-index: 1000000;
  background-color: ${(props) =>
    props.theme.color[
      componentColorToPrimaryColorMap[props.color || ComponentColors.success]
    ]};
  color: ${(props) => props.theme.color.b0};
  padding: ${toThemePixels(4)} ${toThemePixels(3)};
  border-radius: ${toThemePixels(3)};
  position: fixed;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;

  animation: ${ifProp('leaving', leaving, coming)} 200ms;
  ${themeBreakpointDown(Breakpoint.tab)} {
    animation: ${ifProp('leaving', leaving, comingUp)} 200ms;

    top: ${toThemePixels(29)};
    transform: translateX(0%);
    height: fit-content;
    left: 20px !important;
    width: calc(100vw - 40px);
  }

  ${themeBreakpointDown(Breakpoint.sm)} {
    top: 65px;
  }
`
