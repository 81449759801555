import { Badge, Column, Row, Typography } from '@/components'
import { ComponentColors, ThemeColor } from '@/enums'
import { ArrowRight } from '@/icons'
import { paths } from '@/routes/paths'
import { Address } from '@/types/address'
import { YearlyCheckInStatus } from '@/types/assessment'
import { makeAddress, useGoToPath } from '@/utils'
import { useOutletContext } from 'react-router-dom'
import { DashboardCompanyCardItem, LocationCardContent } from './styles'

interface LocationCardProps {
  name: string
  locationId: string
  address: Address
  yearlyCheckInStatus: YearlyCheckInStatus
}

export const LocationCard = ({
  name,
  locationId,
  address,
  yearlyCheckInStatus,
}: LocationCardProps) => {
  const goToLocation = useGoToPath(paths.viewLocationWithKey(locationId))
  const { cart } = useOutletContext<{
    cart: Record<string, string[]>
  }>()

  const isPendingYearlyCheck = yearlyCheckInStatus === 'pending'

  return (
    <DashboardCompanyCardItem onClick={goToLocation}>
      <LocationCardContent>
        <Column gap="4px">
          <Row alignItems="center" gap="8px">
            <Typography bold variant="p1Body">
              {name}
            </Typography>
            <Badge
              color={ComponentColors.danger}
              count={
                (cart[locationId]?.length || 0) + (isPendingYearlyCheck ? 1 : 0)
              }
            />
            <ArrowRight size="message" />
          </Row>
          <Typography color={ThemeColor.b50} variant="badgeText">
            {makeAddress(address)}
          </Typography>
        </Column>
      </LocationCardContent>
    </DashboardCompanyCardItem>
  )
}
