import { makeQueryString } from '@/utils'
import { api } from './api'

export const getSelfAssessment = () => api.get('/self-assessment')

type SelfAssessmentData = {
  businessType?: {
    businessType: string
    locationType: string
    locationSubtypes: string[]
    locationTypeSpecification?: string
  }
  companyDetails?: {
    address: {
      street: string
      streetNumber?: string | undefined
      streetNr?: string | undefined
      postalCode: string
      city: string
    }
  }
  locationDetails?: {
    name: string
    address: {
      street: string
      streetNumber?: string | undefined
      streetNr?: string | undefined
      postalCode: string
      city: string
    }
  }
  companyFoundedDate: { foundedDate: string } | null
  lastYearFinances: {
    approxTurnover: number | null
    approxPersonelExpenses: number | null
    approximateCostOfElectroEquipment: number | null
    approxCostOfGoods: number | null
  } | null
  locationRent: {
    totalEquipmentsCost: number | null
  } | null
  employeesCount: {
    ownersCount: number | null
    fullTimeEmployeesCount: number | null
    partTimeEmployeesCount: number | null
    miniJobbersCount: number | null
  } | null
  inventorySum: number | null
  riskAddress: {
    street: string
    streetNumber: string
    postalCode: string
    city: string
  } | null
  startDate: {
    startDate: string
  }
}

export type GetSelfAssessmentApiResponse = {
  values: SelfAssessmentData
}

export type PutSelfAssessmentPayload = SelfAssessmentData

export const getAssessment = (
  locationId: string,
): Promise<GetSelfAssessmentApiResponse> =>
  // TODO: migrate to new apiClient
  api.get(`/self-assessment/location/${locationId}`)

export const putAssessment = (
  locationId: string,
  body: PutSelfAssessmentPayload,
  isYearlyAssessment: boolean,
  isStepAware: boolean,
) => {
  const url = `user/self-assessment/location/${locationId}${makeQueryString({
    isYearlyAssessment,
    isStepAware,
  })}`
  return api.put(url, body)
}

export const getKillerQuestions = (categoryIds: string[]) =>
  api.get(
    `/killer-questions/questions?${categoryIds
      .map((id) => `categoryId=${id}&`)
      .join('')}`,
  )

export const postKillerAnswers = ({
  answers,
  locationId,
}: {
  locationId: string
  /**
   * TODO: fix type
   */
  answers: any
}) => api.patch(`/locations/${locationId}/killer-questions/answers`, answers)

export const getKillerAnswers = ({
  locationId,
  categoryIds,
}: {
  locationId: string
  categoryIds: string[]
}) =>
  api.get(
    `/locations/${locationId}/killer-questions/answers?${categoryIds
      .map((categoryId) => `categoryId=${categoryId}&`)
      .join('')}`,
  )
