import { dynamicObjectPropType, includesIgnoringCase } from '@/utils'
import PropTypes from 'prop-types'
import {
  propEq,
  curry,
  propOr,
  filter,
  prop,
  pipe,
  when,
  includes,
  not,
  pluck,
  find,
} from 'ramda'

export const getTextFromOptionValue = curry((options, defaultValue) =>
  pipe(
    find(propEq(defaultValue, 'value')),
    propOr(defaultValue, 'children'),
  )(options),
)

export const filterOptions = (inputValue, options) =>
  when(
    pipe(pluck('children'), includes(inputValue), not),
    filter(pipe(prop('children'), includesIgnoringCase(inputValue))),
    options,
  )

export const selectOptionValuePropType = PropTypes.oneOfType([
  PropTypes.number,
  PropTypes.string,
  dynamicObjectPropType,
])
