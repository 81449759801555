import { LineProgress, Typography } from '@/components/atoms'
import { ComponentColors, ThemeColor } from '@/enums'
import { EmptyCircle, TickCircle } from '@/icons'
import { renderableType, useToggable, useTranslationSection } from '@/utils'
import PropTypes from 'prop-types'
import { any, assoc, find, map, prop, propEq, when } from 'ramda'
import { cloneElement, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Column, Divider, Row } from '../../styled'
import { Collapse } from '../Collapse'
import {
  ConnectAccountButton,
  InsuranceButtons,
  OnboardingButton,
  PoaButton,
  prepareChecklist,
} from './helpers'
import { Container, MainCollapseTopContent } from './styles'

const useMessages = () => {
  const { t } = useTranslation()
  const riskChecklistTranslations = useTranslationSection('riskChecklist')

  return {
    title: riskChecklistTranslations('title'),
    checklistItems: t('riskChecklist.checklistItems', { returnObjects: true }),
    onboardingButtonText: riskChecklistTranslations('onboardingButtonText'),
    signPOAButtonText: riskChecklistTranslations('signPOAButtonText'),
    addContractButtonText: riskChecklistTranslations('addContractButtonText'),
    noContractsButtonText: riskChecklistTranslations('noContractsButtonText'),
    connectAccountButtonText: riskChecklistTranslations(
      'connectAccountButtonText',
    ),
  }
}

const ChecklistItem = ({ title, text, isDone = true, actionButtons, open }) => {
  const [isOpen, setIsOpen, negate] = useToggable(open)
  const Buttons = actionButtons
    ? cloneElement(actionButtons, { disabled: isDone })
    : null

  useEffect(() => {
    setIsOpen(open)
  }, [open])

  return (
    <Row gap="16px">
      <div>
        {isDone ? (
          <TickCircle height={24} width={24} />
        ) : (
          <EmptyCircle height={24} width={24} />
        )}
      </div>
      <div>
        <Collapse isOpen={isOpen} onCollapse={negate} title={title}>
          <Column gap="16px">
            <Typography color={ThemeColor.b50} variant="p2Body">
              {text}
            </Typography>
            {Buttons}
          </Column>
        </Collapse>
      </div>
    </Row>
  )
}

const isNotDone = propEq(false, 'isDone')

export const ChecklistWidgetBase = ({ steps, progress }) => {
  const messages = useMessages()
  const shouldExpand = any(isNotDone, steps)
  const [isOpen, setIsOpen] = useState(shouldExpand)

  useEffect(() => {
    setIsOpen(shouldExpand)
  }, [shouldExpand])

  return (
    <Container>
      <Collapse
        isOpen={isOpen}
        onCollapse={() => setIsOpen(!isOpen)}
        title={messages.title}
      >
        <MainCollapseTopContent>
          <LineProgress
            color={ComponentColors.success}
            percentage={progress * 100}
          />
          <Divider color={ThemeColor.b30} />
        </MainCollapseTopContent>
        <Column gap="16px">
          {steps.map((item) => (
            <ChecklistItem key={item.type} {...item} />
          ))}
        </Column>
      </Collapse>
    </Container>
  )
}

ChecklistWidgetBase.propTypes = {
  progress: PropTypes.number,
  steps: PropTypes.arrayOf(ChecklistItem.propTypes),
}

export const ChecklistWidget = ({ data, locationId, companyId }) => {
  const messages = useMessages()
  const [childOpen, setChildOpen] = useState('')
  const stepButtons = useMemo(
    () => ({
      onboarding: (
        <OnboardingButton
          locationId={locationId}
          text={messages.onboardingButtonText}
        />
      ),
      signedPOA: (
        <PoaButton companyId={companyId} text={messages.signPOAButtonText} />
      ),
      contractAdded: (
        <InsuranceButtons
          locationId={locationId}
          text={{
            text1: messages.addContractButtonText,
            text2: messages.noContractsButtonText,
          }}
        />
      ),
      bankAccountAdded: (
        <ConnectAccountButton text={messages.connectAccountButtonText} />
      ),
    }),
    [locationId, companyId],
  )
  const steps = prepareChecklist(messages.checklistItems, data, stepButtons)

  useEffect(() => {
    const firstNotCompleted = find(isNotDone, prop('checks', data))
    setChildOpen(firstNotCompleted?.type)
  }, [JSON.stringify(data.checks)])

  return (
    <ChecklistWidgetBase
      progress={data.progress}
      steps={map(when(propEq(childOpen, 'type'), assoc('open', true)), steps)}
    />
  )
}

ChecklistWidget.propTypes = {
  companyId: PropTypes.string,
  data: PropTypes.shape({
    checks: PropTypes.arrayOf(
      PropTypes.shape({
        isDone: PropTypes.bool,
        type: PropTypes.string,
      }),
    ),
    progress: PropTypes.number,
  }),
  locationId: PropTypes.string,
}

ChecklistItem.propTypes = {
  actionButtons: renderableType,
  isDone: PropTypes.bool,
  open: PropTypes.bool,
  text: PropTypes.string,
  title: PropTypes.string,
}
