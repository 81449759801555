import PropTypes from 'prop-types'
import { Column, FormAddressAutocomplete, Typography } from '@/components'
import { ThemeColor } from '@/enums'
import { useFormContext, useSnackbar } from '@/providers'
import { useCompany, useUpdateAddress, useUpdateCompany } from '@/services'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useValidateCheckoutStep } from './helpers'

const CompanyDetailsForm = () => {
  const { t } = useTranslation()

  return (
    <Column gap="8px">
      <Column gap="4px">
        <Typography bold variant="h4">
          {t('checkout.titles.companyDetails')}
        </Typography>
        <Typography color={ThemeColor.glass500} variant="description">
          {t('checkout.subtitles.companyDetails')}
        </Typography>
      </Column>
      <FormAddressAutocomplete name="companyDetails" />
    </Column>
  )
}

export const CompanyDetailsStep = ({ setButtonState, companyId, onNext }) => {
  const { t } = useTranslation()
  const snackbar = useSnackbar()
  const { values } = useFormContext()
  const { data: companyData } = useCompany(companyId)
  const updateCompanyMutation = useUpdateCompany(companyId)
  const updateAddressMutation = useUpdateAddress(
    companyData?.address?.addressId,
  )

  const handleSubmit = async () => {
    const addressWithCountry = {
      ...values.companyDetails.address,
      country: 'Germany',
    }
    try {
      await Promise.all([
        updateCompanyMutation.mutateAsync({
          name: values.companyDetails.name,
        }),
        updateAddressMutation.mutateAsync(addressWithCountry),
      ])
      onNext()
    } catch (_e) {
      snackbar.error(t('snackbars.error.generalMsg'))
    }
  }

  const handleNext = useValidateCheckoutStep(handleSubmit)

  useEffect(() => {
    setButtonState({
      onClick: handleNext,
      loading:
        updateAddressMutation.isLoading || updateCompanyMutation.isLoading,
    })

    return () => {
      setButtonState({ loading: false })
    }
  }, [values, updateAddressMutation.isLoading, updateCompanyMutation.isLoading])

  return <CompanyDetailsForm />
}

CompanyDetailsStep.propTypes = {
  companyId: PropTypes.string,
  onNext: PropTypes.func,
  setButtonState: PropTypes.func,
}
